import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '../../../../common/Tabs';
import ViewSmall from '../../../../icons/ViewSmall';
import ViewMedium from '../../../../icons/ViewMedium';
import ViewBig from '../../../../icons/ViewBig';
import CertificatesItem from '../../../BrandsNew/components/CertificatesItem';
import styles from '../../index.module.css';
import cn from 'classnames';

export const ProfileSertificates = () => {
    const [view, setView] = useState('big');
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/user/me/`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((response) => {
                setTokens(response);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div className={styles.profileTokensContent}>
            <Tabs changeMobileTheme>
                <Tab left active={view === 'small'} onClick={() => setView('small')}>
                    <ViewSmall />
                </Tab>

                <Tab active={view === 'normal'} onClick={() => setView('normal')}>
                    <ViewMedium />
                </Tab>

                <Tab right active={view === 'big'} onClick={() => setView('big')}>
                    <ViewBig />
                </Tab>
            </Tabs>

            <div
                className={cn(styles.profileTokensItems, {
                    [styles.smallCards]: view === 'small',
                    [styles.normalCards]: view === 'normal',
                })}
            >
                {tokens.results &&
                    tokens.results.map((token, index) => {
                        return (
                            <CertificatesItem
                                key={index}
                                certificate={{
                                    id: token.id,
                                    name: token.name,
                                    price: token.price,
                                    file_1: `${token.file_1}`,
                                    investor_royalty: token.investor_royalty,
                                    purchased: true,
                                }}
                                view={view}
                            />
                        );
                    })}
            </div>
        </div>
    );
};
