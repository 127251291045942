import { uid } from 'uid';
export const handleCopySelectedPack = (el) => {
    const accessToken = localStorage.getItem('access_token');

    const requestBody = {
        investor_royalty: el.investor_royalty,
        title: el.title,
        creator_royalty: el.creator_royalty,
        title: el.title,
        description: el.description,
        collection: el.collection.id,
        name: `${el.name} ${uid()}`,
        price: el.price,
        currency_token: el.currency_token.id,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/pack/`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        method: 'POST',
    })
        .then((res) => {
            if (Number(res.status) >= 400) {
                throw new Error(res);
            }
        })
        .catch((err) => {});
};
