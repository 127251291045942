import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterTable from '../../components/FilterTable';
import TableCell from '../../components/TableRow/TableCell';
import TableRow from '../../components/TableRow';
import { BREAKPOINTS } from '../../const/breakpoints/BREAKPOINTS';
import { Link, useParams } from 'react-router-dom';
// import { InfiniteScrollWrapper } from '../../common/InfiniteScrollWrapper';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeSearchTokenName,
    changeStatus,
    changeType,
    // tokensAddPageData,
    // tokensIncrementPage,
    tokensRemove,
    // tokensSetLastItemsCount,
} from './slice/slice';
// import { CommonSkeleton } from '../Statistics2/CommonSkeleton';
import moment from 'moment';

import {
    useCancelTransactionMutation,
    useConfirmTransactionMutation,
    useLazyGetTransactionListQuery,
    useTransformTransactionInWorkMutation,
} from '../../redux/api/billingService';
import { CancelModal } from './modals/CancelModal';
import { useWindowResize } from '../../hooks/useWindowResize';
import { TokenActionAdminModal } from './modals/TokenActionAdminModal';
// import { setIsRefetch } from '../../redux/slices/collections';
import { useGetTableView } from '../useGetTableView';
import { useGenerateRoutes } from '../useGetTableView';
import { expandListOfParameters } from '../utils';
import { useDynamicPagination } from '../useDynamicPagination';
import { useOutletContext } from 'react-router-dom';
moment.locale('ru');

const sortList = [{ value: 'recent', name: 'Recent changes' }];

const sortList2 = [{ value: '90', name: 'Last 90 Days' }];

export const helperCost = (value) => {
    if (value !== undefined) {
        const fractionPart = (value / 100) % 1;

        const formattedFractionPart = fractionPart.toFixed(2).substring(1);

        return `${Math.floor(value / 100).toLocaleString('ru-RU')}${
            Number(formattedFractionPart) > 0 ? formattedFractionPart : ''
        }`;
    }
};

const TABLE_URL = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/token/`;

export const Conclusions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { collectionId, pageId, accountId } = useParams();
    const { isDesktop } = useWindowResize();
    const [isActiveFilters, setIsActiveFilters] = useState(isDesktop ? true : false);
    const [collectionName, setCollectionName] = useState('');
    const getTableViewParam = Boolean(new URLSearchParams(window.location.search).get('all'));
    const [isCancelModalActive, setIsCancelModalActive] = useState(false);
    const [isTokenClicked, setIsTokenClicked] = useState(false);
    const [clickedId, setClickedId] = useState(0);
    const [clickedToken, setClickedToken] = useState();
    const [transformToWork] = useTransformTransactionInWorkMutation();
    const [confirmTransaction] = useConfirmTransactionMutation();
    const [cancelTransaction] = useCancelTransactionMutation();
    const tokenSlice = useSelector((state) => state.conclusionsSlice);

    const [requestParams, isResetList, setIsResetList, setCount, setCurrentPage] =
        useOutletContext();

    useGetTableView(location.pathname, getTableViewParam, setCurrentPage, 'tokens');
    useGenerateRoutes([
        { title: 'page', id: pageId },
        { title: 'account', id: accountId },
        { title: 'collection', id: collectionId },
        // { title: 'pack', id: packId },
    ]);
    const params = useMemo(() => {
        return expandListOfParameters(requestParams, [
            {
                title: 'account_id',
                value: accountId,
            },
            {
                title: 'page_id',
                value: pageId,
            },
            {
                title: 'collection_id',
                value: collectionId,
            },
        ]);
    }, [accountId, pageId, requestParams, requestParams, collectionId]);

    const items = useDynamicPagination(TABLE_URL, params, isResetList, setIsResetList, setCount);

    const [filterData, setFilterData] = React.useState([
        {
            id: 1,
            name: 'Вывод',
            active: false,
            value: 'output',
        },
        {
            id: 2,
            name: 'Возврат',
            active: false,
            value: 'refund',
        },
    ]);

    const [filterStatus, setFilterStatus] = React.useState([
        {
            id: 3,
            name: 'Создано',
            active: true,
            value: 'created',
        },
        {
            id: 4,
            name: 'В работе',
            active: false,
            value: 'in_work',
        },
        {
            id: 5,
            name: 'Подтверждено',
            active: false,
            value: 'confirmed',
        },
        {
            id: 6,
            name: 'Отменено',
            active: false,
            value: 'cancelled',
        },
    ]);

    const onChange = (item) => {
        const arr = filterData.map((el) => ({
            ...el,
            active: item.name === el.name,
        }));

        setFilterData(arr);

        dispatch(changeType(item.value));
        dispatch(tokensRemove());
    };

    const onRemoveItem = (item) => {
        const arr = filterData.map((el) => ({
            ...el,
            active: item === el.id ? false : el.active,
        }));

        setFilterData(arr);

        dispatch(changeType(''));
        dispatch(tokensRemove());
    };

    const handleChangeStatus = (item) => {
        const arr = filterStatus.map((el) => ({
            ...el,
            active: item.value === el.value,
        }));

        setFilterStatus(arr);

        dispatch(changeStatus(item.value));
        dispatch(tokensRemove());
    };

    const handleRemoveStatus = (item) => {
        const arr = filterStatus.map((el) => ({
            ...el,
            active: item === el.id ? false : el.active,
        }));

        setFilterStatus(arr);

        dispatch(changeStatus(''));
        dispatch(tokensRemove());
    };

    // const filterChange = (value) => {
    //     setFilter(value);
    // };

    // const filterChange2 = (value) => {
    //     setFilter2(value);
    // };

    const handlerInWorkClick = (id) => {
        transformToWork({ id })
            .unwrap()
            .then(() => {
                dispatch(tokensRemove());
            });
    };

    const handlerConfirmClick = (id) => {
        confirmTransaction({ id })
            .unwrap()
            .then(() => {
                dispatch(tokensRemove());
            });
    };

    const handlerCancelClick = (id) => {
        cancelTransaction({ id })
            .unwrap()
            .then(() => {
                dispatch(tokensRemove());
            });
    };

    const handleGetTokenStatus = (status) => {
        if (status === 'confirmed') return 'Подтверждено';
        if (status === 'created') return 'Создано';
        if (status === 'in_work') return 'В работе';
        if (status === 'cancelled') return 'Отменено';
    };

    // useEffect(() => {
    //     return () => {
    //         setPackName('');
    //         setPackName('');
    //         dispatch(tokensRemove());
    //     };
    // }, []);

    const sortedItems = useMemo(() => {
        if (items && items.length !== 0) {
            // return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            let clone = [];
            items.items.forEach((element) => {
                clone.push(element);
            });

            return clone.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        return [];
    }, [items]);

    const handleNavigate = useCallback(
        (el, type) => {
            if (type === 'navigateNext' && el && el.page && el.account) {
                navigate(`../tokens/${el.page.id}/${el.id}/${el.account.id}`);
            }
        },
        [items],
    );

    return (
        <>
            <TableRow names differentColumn>
                <TableCell title largeColumn>
                    Статус
                </TableCell>

                <TableCell title largeColumn>
                    Токен
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD620}>
                    Стоимость
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    <p
                        style={{
                            color: 'hsla(42, 85%, 59%, 1)',
                        }}
                    >
                        Начислено
                    </p>
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    <p
                        style={{
                            color: 'hsla(126, 40%, 62%, 1)',
                        }}
                    >
                        Выведено
                    </p>
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    <p
                        style={{
                            color: 'hsla(210, 77%, 51%, 1)',
                        }}
                    >
                        К выводу
                    </p>
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1200}>
                    <p
                        style={{
                            color: 'hsla(304, 23%, 51%, 1)',
                        }}
                    >
                        К возврату
                    </p>
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD700}>
                    Владелец
                </TableCell>

                <TableCell title textCenter breakpoint={BREAKPOINTS.MD1400}>
                    Дата
                </TableCell>
            </TableRow>

            {sortedItems.length > 0 &&
                sortedItems.map((el, i) => {
                    return (
                        <TableRow
                            differentColumn
                            actionType={
                                el.status === 'created'
                                    ? 'tokensAdmin'
                                    : el.status === 'in_work'
                                    ? 'inWorkStatus'
                                    : ''
                            }
                            key={i}
                            handlerInWorkClick={() => handlerInWorkClick(el.id)}
                            handlerConfirmClick={() => handlerConfirmClick(el.id)}
                            handlerCancelClick={() => {
                                setIsCancelModalActive(true);
                                setClickedId(el.id);
                            }}
                        >
                            <TableCell
                                largeColumn
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p className="whithdraw__index">{i + 1}</p>

                                <div
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        borderRadius: '999px',
                                        background:
                                            el.status === 'confirmed'
                                                ? 'hsla(125, 41%, 62%, 1)'
                                                : el.status === 'created'
                                                ? 'hsla(210, 77%, 51%, 1)'
                                                : el.status === 'in_work'
                                                ? 'hsla(42, 85%, 59%, 1)'
                                                : 'hsla(0, 54%, 56%, 1)',
                                    }}
                                />

                                <div className="stats__item--wrapper withdraw__item">
                                    <p
                                        className={`withdraw__item--collection--name ${
                                            el.paid
                                                ? 'stats__item--text--blue'
                                                : 'stats__item--text--white'
                                        }`}
                                        style={{
                                            color:
                                                el.status === 'confirmed'
                                                    ? 'hsla(125, 41%, 62%, 1)'
                                                    : el.status === 'created'
                                                    ? 'hsla(210, 77%, 51%, 1)'
                                                    : el.status === 'in_work'
                                                    ? 'hsla(42, 85%, 59%, 1)'
                                                    : 'hsla(0, 54%, 56%, 1)',
                                        }}
                                    >
                                        {handleGetTokenStatus(el.status)}
                                    </p>
                                </div>
                            </TableCell>

                            <TableCell
                                largeColumn
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <div className="stats__item--avatar--inner withdraw__item">
                                    {el && el.file_1 && (
                                        <img
                                            src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${el.file_1}`}
                                            alt={'img'}
                                            style={{
                                                objectFit: 'cover',
                                                width: '48px',
                                                height: '48px',
                                            }}
                                        />
                                    )}
                                </div>

                                <div className="stats__item--wrapper withdraw__item">
                                    {el && (
                                        <Link
                                            to={`/token/${el.id}`}
                                            className="withdraw__item--collection--name"
                                        >
                                            {el.collection && el.collection.name}
                                            <br />
                                            <b>{el.name}</b>
                                        </Link>
                                    )}
                                </div>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                {el && el.price && `${helperCost(el.price)} RUB`}
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        color: 'hsla(42, 85%, 59%, 1)',
                                    }}
                                >
                                    {el &&
                                        el.token_account &&
                                        `${helperCost(el.accrued + el.frozen_amount + el.withdrawn)}


`}
                                    RUB
                                </p>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        color: 'hsla(126, 40%, 62%, 1)',
                                    }}
                                >
                                    {el &&
                                        el.token_account &&
                                        `  ${helperCost(el.token_account.withdrawn)} RUB`}
                                </p>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        color: 'hsla(210, 77%, 51%, 1)',
                                    }}
                                >
                                    {el.type === 'output' ? `${helperCost(el.amount)} RUB` : '---'}
                                </p>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD620}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        color: 'hsla(304, 23%, 51%, 1)',
                                    }}
                                >
                                    {el.type === 'refund' ? `${helperCost(el.amount)} RUB` : '---'}
                                </p>
                            </TableCell>

                            <TableCell
                                linkWithText
                                textCenter
                                breakpoint={BREAKPOINTS.MD700}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                <p
                                    style={{
                                        maxWidth: '180px',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {el.wallet_owner ? el.wallet_owner : '---'}
                                </p>
                            </TableCell>

                            <TableCell
                                text
                                textCenter
                                breakpoint={BREAKPOINTS.MD1400}
                                onClick={() => {
                                    if (el.type === 'output' || el.type === 'refund') {
                                        setClickedToken(el);
                                        setIsTokenClicked(true);
                                    }
                                }}
                            >
                                {moment(el.created_at).format('l')}
                            </TableCell>
                        </TableRow>
                    );
                })}
            <CancelModal
                handleOk={() => {
                    handlerCancelClick(clickedId);
                    setIsCancelModalActive(false);
                }}
                setActive={setIsCancelModalActive}
                active={isCancelModalActive}
            />
            <TokenActionAdminModal
                isOpen={isTokenClicked}
                setIsOpen={setIsTokenClicked}
                title={
                    clickedToken && clickedToken.type === 'output'
                        ? 'Оформление вывода'
                        : 'Оформление возврата'
                }
                token={clickedToken}
                type={clickedToken && clickedToken.type === 'output' ? 'withdrawal' : 'return'}
                tokenRefetch={() => dispatch(tokensRemove())}
            />
        </>
    );
};
