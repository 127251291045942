import React from 'react';

const Qr = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.5 4C4.224 4 4 4.224 4 4.5V10.5C4 10.776 4.224 11 4.5 11H10.5C10.776 11 11 10.776 11 10.5V4.5C11 4.224 10.776 4 10.5 4H4.5ZM13.5 4C13.224 4 13 4.224 13 4.5V10.5C13 10.776 13.224 11 13.5 11H19.5C19.776 11 20 10.776 20 10.5V4.5C20 4.224 19.776 4 19.5 4H13.5ZM5 5H10V10H5V5ZM14 5H19V10H14V5ZM6 6V9H9V6H6ZM15 6V9H18V6H15ZM4.5 13C4.224 13 4 13.224 4 13.5V19.5C4 19.776 4.224 20 4.5 20H10.5C10.776 20 11 19.776 11 19.5V13.5C11 13.224 10.776 13 10.5 13H4.5ZM13.5 13C13.224 13 13 13.224 13 13.5V16H14V14H16V13H13.5ZM17.5 13C17.224 13 17 13.224 17 13.5V16H18V14H20V13H17.5ZM5 14H10V19H5V14ZM6 15V18H9V15H6ZM15 16V17H14.5C14.224 17 14 17.224 14 17.5V20H15V18H16V20H17V17.5C17 17.224 16.776 17 16.5 17H16V16H15ZM19 16V20H20V16H19Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Qr;
