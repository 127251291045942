import React from 'react';

export const User3 = ({ ...props }) => {
    return (
        <svg
            width="25"
            height="28"
            viewBox="0 0 25 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.04297 9.62891V5.36198C6.04297 3.93989 6.60789 2.57605 7.61346 1.57049C8.61902 0.564921 9.98286 0 11.4049 0H13.5951C15.0171 0 16.381 0.564921 17.3865 1.57049C18.3921 2.57605 18.957 3.93989 18.957 5.36198V9.62891C18.957 11.0527 18.3927 12.4185 17.3877 13.4271C16.3827 14.4357 15.0189 15.0048 13.5951 15.0098H11.4049C9.98113 15.0048 8.61733 14.4357 7.61231 13.4271C6.60728 12.4185 6.04296 11.0527 6.04297 9.62891ZM1.28516 25.2806C8.55817 27.7479 16.4418 27.7479 23.7148 25.2806C24.1774 25.1248 24.5616 24.7959 24.7868 24.3629C25.0121 23.9299 25.061 23.4265 24.9232 22.9583C24.4839 21.4857 23.6182 20.1762 22.4355 19.195C21.2527 18.2138 19.8058 17.605 18.2773 17.4453C16.8689 18.3128 15.2492 18.7765 13.5951 18.7858H11.4049C9.75083 18.7765 8.13107 18.3128 6.72266 17.4453C5.19421 17.605 3.74731 18.2138 2.56453 19.195C1.38175 20.1762 0.516107 21.4857 0.0768238 22.9583C-0.060982 23.4265 -0.01212 23.9299 0.213162 24.3629C0.438444 24.7959 0.822641 25.1248 1.28516 25.2806Z"
                fill="currentColor"
            />
        </svg>
    );
};
