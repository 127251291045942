import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import About from '../../components/About';
import BrandBg from '../../components/BrandBg';
import ImgBlock from '../../components/ImgBlock';
import { useGetAccountsListFilteredQuery } from '../../redux/api/handleService';
import { useGetPageByUrlQuery } from '../../redux/api/dataService';

import TemplatePageSkeleton from './TemplatePageSkeleton';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import ImgBlockItem from '../../components/ImgBlock/ImgBlockItem';
import Skeleton from 'react-loading-skeleton';
import isElementIntoViewPort from '../../utils/isElementIntoViewPort';

const PAGE_SIZE = 15;

const TemplatePage = () => {
    const { url } = useParams();
    const navigate = useNavigate();

    const {
        data: pageInfo,
        isLoading: isPageLoading,
        error: pageInfoError,
    } = useGetPageByUrlQuery(url);

    const [pageAccaunts, setPageAccaunts] = useState([]);
    const [totalAccountsCount, setTotalAccountsCount] = useState(0);
    const [page, setPage] = useState(1);

    const totalAccountsRef = useRef(0);
    const totalPagesRef = useRef(0);
    const isDataLoadedRef = useRef(false);

    const undrownIndex = useRef(-1);
    const itemsContainer = useRef(null);

    const { data: pageAccauntsData, isLoading: isAccountsLoading } =
        useGetAccountsListFilteredQuery(
            {
                page,
                pageSize: PAGE_SIZE,
                pageId: pageInfo && pageInfo.id,
            },
            {
                skip: !pageInfo,
            },
        );

    const scrollHandler = useDebouncedCallback(
        () => {
            if (itemsContainer.current && undrownIndex.current > -1) {
                const elem = Array.from(itemsContainer.current.children)[undrownIndex.current];

                if (elem && isElementIntoViewPort(elem)) {
                    setPage((p) => {
                        if (p + 1 <= totalPagesRef.current) {
                            isDataLoadedRef.current = true;
                            setPageAccaunts((p) => [
                                ...p,
                                ...Array.from(Array(PAGE_SIZE).keys()).map((i) => ({
                                    id: i,
                                    shouldBeSkeleton: true,
                                })),
                            ]);

                            undrownIndex.current = -1;

                            return p + 1;
                        }
                        return p;
                    });
                }
            }
        },
        [],
        500,
    );

    useEffect(() => {
        if (pageAccauntsData) {
            const pageData = pageAccauntsData.page;
            const pageSizeData = pageAccauntsData.page_size;

            const startIndex = pageSizeData * (pageData - 1);

            totalAccountsRef.current = pageAccauntsData.count;
            setTotalAccountsCount(pageAccauntsData.count);
            totalPagesRef.current = pageAccauntsData.total_pages;

            setPageAccaunts((p) => {
                return [...p.slice(0, startIndex), ...pageAccauntsData.results];
            });

            isDataLoadedRef.current = false;
        }
    }, [pageAccauntsData]);

    useEffect(() => {
        if (pageInfoError && pageInfoError.status === 404) {
            navigate('/');
        }
    }, [pageInfoError]);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    if (isPageLoading || !pageInfo || isAccountsLoading) {
        return <TemplatePageSkeleton />;
    }

    return (
        <>
            <BrandBg src={pageInfo.banner} />

            <div className="aboutblock">
                <div className="container">
                    <div className="aboutblock__inner">
                        <About title={pageInfo.title_1} text={pageInfo.description} />

                        <h2 className="imgblock__title">{pageInfo.title_2}</h2>

                        {pageAccaunts && pageAccaunts.length > 0 ? (
                            <div ref={itemsContainer} className="imgblock__content">
                                {pageAccaunts.map((item) => (
                                    <ImgBlockItem
                                        key={item.id}
                                        name={item.name}
                                        path={item.logo}
                                        id={item.id}
                                        shouldBeSkeleton={item.shouldBeSkeleton}
                                        collectionsCount={item.collections_count}
                                    />
                                ))}
                                {totalAccountsCount - pageAccaunts.length > 0 &&
                                    Array.from(
                                        Array(totalAccountsCount - pageAccaunts.length).keys(),
                                    ).map((idx) => {
                                        if (undrownIndex.current === -1) {
                                            undrownIndex.current = idx + pageAccaunts.length;
                                        }
                                        return (
                                            <div key={idx} className="imgblock__item">
                                                <div className="imgblock__item--inner">
                                                    <Skeleton
                                                        containerClassName="imgblock__item--skeleton"
                                                        className="skeleton__content"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div className="collection__items--none imgblock__none">
                                No items to display
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(TemplatePage);
