import React from 'react';

const Vk2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.9836 3H9.35983C4.221 3 3 4.22192 3 9.34975V14.9744C3 20.1114 4.21275 21.3333 9.34975 21.3333H14.9744C20.1114 21.3333 21.3333 20.1206 21.3333 14.9836V9.35983C21.3333 4.221 20.1206 3 14.9836 3ZM17.8005 16.0808H16.4631C15.9571 16.0808 15.8049 15.6711 14.8974 14.7636C14.1054 14 13.7708 13.9047 13.5701 13.9047C13.2933 13.9047 13.2172 13.9808 13.2172 14.363V15.5657C13.2172 15.8911 13.1118 16.0817 12.262 16.0817C11.4375 16.0257 10.638 15.775 9.92918 15.35C9.22039 14.925 8.62252 14.3379 8.18467 13.637C7.14549 12.345 6.42286 10.8278 6.0745 9.20675C6.0745 9.006 6.15058 8.8245 6.53283 8.8245H7.87025C8.214 8.8245 8.33775 8.97758 8.47158 9.3305C9.12058 11.2408 10.2279 12.9018 10.6771 12.9018C10.8494 12.9018 10.9246 12.8258 10.9246 12.3958V10.4287C10.8678 9.53125 10.3911 9.45517 10.3911 9.13067C10.3972 9.04508 10.4364 8.96524 10.5004 8.90808C10.5644 8.85092 10.6482 8.82094 10.7339 8.8245H12.8349C13.1218 8.8245 13.2172 8.9675 13.2172 9.31125V11.9659C13.2172 12.2528 13.3409 12.3482 13.4271 12.3482C13.5994 12.3482 13.7323 12.2528 14.0477 11.9375C14.7249 11.1115 15.2783 10.1913 15.6903 9.20583C15.7327 9.08763 15.8125 8.98646 15.9175 8.91765C16.0225 8.84884 16.1471 8.81614 16.2724 8.8245H17.6098C18.0104 8.8245 18.0957 9.02525 18.0104 9.31125C17.5241 10.4007 16.9224 11.4348 16.2156 12.3958C16.0717 12.6158 16.0139 12.7304 16.2156 12.988C16.3485 13.1888 16.8169 13.5802 17.1322 13.9523C17.5903 14.4096 17.9708 14.9384 18.2588 15.518C18.3734 15.8902 18.1818 16.0808 17.8005 16.0808Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Vk2;
