import React from 'react';

const Web2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0969 3.33594C8.45401 3.81452 6.99348 4.77716 5.90588 6.09827C4.81827 7.41938 4.1541 9.03762 4 10.7419H7.88571C8.11222 8.14845 8.86414 5.62827 10.0957 3.33472L10.0969 3.33594ZM7.88571 12.2597H4C4.15378 13.964 4.81767 15.5824 5.90506 16.9038C6.99245 18.2251 8.45284 19.188 10.0957 19.6669C8.86414 17.3733 8.11222 14.8531 7.88571 12.2597ZM12.0434 19.9911C10.5712 17.6561 9.66858 15.0078 9.40843 12.2597H15.5224C15.2622 15.0078 14.3596 17.6561 12.8874 19.9911C12.6062 20.0051 12.3246 20.0051 12.0434 19.9911ZM14.8363 19.6657C16.479 19.1869 17.9392 18.2241 19.0266 16.903C20.114 15.582 20.778 13.9638 20.932 12.2597H17.0463C16.8198 14.8531 16.0679 17.3733 14.8363 19.6669V19.6657ZM17.0463 10.7419H20.932C20.7782 9.03755 20.1143 7.41916 19.0269 6.09783C17.9396 4.7765 16.4792 3.81359 14.8363 3.33472C16.0679 5.62827 16.8198 8.14845 17.0463 10.7419ZM12.0434 3.01051C12.325 2.9965 12.607 2.9965 12.8886 3.01051C14.3604 5.34556 15.2626 7.99393 15.5224 10.7419H9.40964C9.67314 7.97694 10.5778 5.32615 12.0434 3.01051Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Web2;
