import React from 'react';

const ShareLink = ({ ...props }) => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.38331 23.3942C9.41126 23.3983 9.43832 23.4008 9.46447 23.4008C9.65565 23.4008 9.83149 23.2917 9.89824 23.1236C9.96316 22.9638 11.4935 19.3183 16.704 19.7381V22.8892C16.704 23.0507 16.8059 23.1999 16.9673 23.2688C17.1288 23.3377 17.3181 23.3172 17.457 23.2139L25.0295 17.6015C25.1359 17.5236 25.1954 17.408 25.1999 17.2842C25.2026 17.1604 25.1449 17.0432 25.043 16.9611L17.4705 10.905C17.3344 10.796 17.1405 10.7706 16.9746 10.8378C16.8086 10.905 16.7022 11.0559 16.7022 11.2199V14.3267C15.6796 14.3414 13.5027 14.5702 11.6946 16.1002C9.91716 17.6031 9.00993 19.9185 9 22.9787C9.00271 23.1845 9.16146 23.3581 9.38331 23.3942Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ShareLink;
