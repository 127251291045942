import Modal from '../../../common/Modal';
import { useInitialMutation } from '../../../redux/api/dataService';
import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../common/Input';
import { useDispatch, useSelector } from 'react-redux';
import { setIsBuyCertificateModalActive } from '../../../redux/slices/auth';
import { NotificationContext } from '../../../context/NotificationContext';
import NOTIFICATION_TYPES from '../../../const/notifications/NOTIFICATION_TYPES';
import s from '../modals/TokenActionsModal/index.module.css';
import styles from '../../TokenSuccessBuy/tokenBuy.module.css';
import { Checkbox } from '@mui/material';

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PayModal = ({ isPay, setIsPay, token, collection }) => {
    const dispatch = useDispatch();
    const [initialPay] = useInitialMutation();

    const [email, setEmail] = useState('');
    const [isSendData, setIsSendData] = useState(false);

    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);

    const emailValue = useSelector((state) => state.auth.email);

    const emailRegex = /\S+@\S+\.\S+/;

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const handleButtonClick = () => {
        setIsSendData(true);
        if (emailRegex.test(email) && isChecked1 && isChecked2) {
            initialPay({
                email: email,
                tokens: [token.id],
            })
                .unwrap()
                .then((result) => {
                    setIsPay(false);
                    setEmail('');
                    dispatch(setIsBuyCertificateModalActive(false));

                    const link = document.createElement('a');
                    link.href = result.payment_url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(() => {
                    addNotification({
                        type: NOTIFICATION_TYPES.ERROR,
                        text: 'Платеж не удалось инициализировать, пожалуйста, повторите попытку позже.',
                    });
                    setIsPay(false);
                    setEmail('');
                    dispatch(setIsBuyCertificateModalActive(false));
                });
        }
    };

    useEffect(() => {
        if (emailValue) {
            setEmail(emailValue);
        }
    }, [emailValue]);

    const handleCheckOffer = () => {
        if (collection && collection.offer) {
            window.open(collection.offer, '_target');
        }
    };

    return (
        <Modal
            active={isPay}
            title={'Оформление покупки'}
            setActive={setIsPay}
            onClose={() => {
                setIsPay(false);
                setEmail('');
            }}
        >
            <p
                style={{
                    color: 'hsla(210, 77%, 51%, 1)',
                    marginBottom: '40px',
                }}
            >
                Цифровой сертификат
            </p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div className={s.box}>
                    {token && token.file_1 && (
                        <img src={token.file_1} className={styles.img} alt={'token image'} />
                    )}
                    <div className={s.block}>
                        <a className={s.link} href={`/collection/${token.collection.id}`}>
                            {token && token.collection && token.collection.name}
                        </a>
                        <p>{token && token.name}</p>
                        <p>
                            {token && token.investor_royalty
                                ? `Доходность: ${token.investor_royalty}%/день`
                                : ''}
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        color: 'white',
                    }}
                >
                    <p>Стоимость</p>
                    <p
                        style={{
                            whiteSpace: 'nowrap',
                            marginTop: '3px',
                        }}
                    >
                        {Math.floor(token.price / 100).toLocaleString('ru-RU')} RUB
                    </p>
                </div>
            </div>

            <p
                style={{
                    marginRight: 'auto',
                    color: 'white',
                    marginBottom: '5px',
                    marginTop: '50px',
                }}
            >
                Адрес электронной почты:
            </p>
            <Input
                title=""
                placeholder="Введите email"
                value={email}
                setValue={setEmail}
                isError={
                    isSendData ? !emailRegex.test(email) : email ? !emailRegex.test(email) : false
                }
            />

            <button
                className="button token__card--content--price--buy"
                onClick={handleButtonClick}
                style={{ height: '52px', width: '100%' }}
            >
                Перейти к оплате
            </button>

            <div
                style={{
                    margin: '20px 0',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        color: 'white',
                    }}
                >
                    <Checkbox
                        checked={isChecked1}
                        style={{ width: '14px', height: '14px', marginRight: '10px' }}
                        onChange={() => setIsChecked1(!isChecked1)}
                        className={s.custom_checkbox}
                    />

                    <p>
                        Я принимаю условия{' '}
                        <span
                            onClick={handleCheckOffer}
                            style={{ color: 'hsla(210, 77%, 51%, 1)', textDecoration: 'underline' }}
                        >
                            Договора оферты
                        </span>
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        color: 'white',
                    }}
                >
                    <Checkbox
                        checked={isChecked2}
                        style={{ width: '14px', height: '14px', marginRight: '10px' }}
                        onChange={() => setIsChecked2(!isChecked2)}
                        className={s.custom_checkbox}
                    />
                    <p>
                        Я согласен на обработку моих персональных данных в соответствии с{' '}
                        <span
                            style={{ color: 'hsla(210, 77%, 51%, 1)', textDecoration: 'underline' }}
                        >
                            Условиями
                        </span>
                    </p>
                </div>
            </div>
            <Input
                placeholder="Цифровой сертификат позволяет владеть исключительными правами на изображение и получать вознаграждение от использования этих прав. После ввода email вы будете переведены на сервис банка для проведения оплаты. Цифровой сертификат позволяет владеть исключительными правами на изображение и получать вознаграждение от использования этих прав. После ввода email вы будете переведены на сервис банка для проведения оплаты."
                textarea
                disabled={true}
            />
        </Modal>
    );
};
