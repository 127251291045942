import { useCallback } from 'react';
import { ethers } from 'ethers';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { getSigner } from '../functions/auth';
import ABI from './abi.json';

const parseContractAddress = '0x300b07C90152E312c7F6c231c939716eBd2Ce896';

const whitelisted = [
    '0xDA098fAB7B291C6025cE432Db2a5af421157f02e',
    '0xD8E2Bb4c335304c2F4d93C67235634d199407192',
    '0xA05082fe2957392a67D33d0874a6B41bA4E0a7ac',
    '0x65Ed0458D0967ddAfCA7585aF492c4ae7c485953',
    '0xea09d6d8cff17b11e45763d1025831de3e2ddaaf',
];

const useUploadToBlockChain = () => {
    // const whitelistMintToken = useCallback(async ({ userAddress, tokenPrice, tokenMetaData }) => {
    //     try {
    //         const signer = await getSigner();

    //         const abiStr = JSON.stringify(ABI);

    //         const new_contract = new ethers.Contract(
    //             '0x300b07C90152E312c7F6c231c939716eBd2Ce896',
    //             abiStr,
    //             signer,
    //         );

    //         const tx = await new_contract.mint(1, { value: await new_contract.cost() });

    //         await tx.wait();

    //         const newState = await new_contract.totalSupply();

    //         return newState;
    //     } catch (e) {

    //     }
    // }, []);

    const whitelistMintToken = useCallback(async ({ userAddress }) => {
        try {
            const leafNodes = whitelisted.map((addr) => keccak256(addr));

            const merkleTree = new MerkleTree(leafNodes, keccak256, {
                sortPairs: true,
            });

            const rootHash = '0x' + merkleTree.getRoot().toString('hex');

            const signer = await getSigner();

            if (signer) {
                let contract = new ethers.Contract(parseContractAddress, ABI, signer);

                const tx = await contract.whitelistMint(
                    1,
                    merkleTree.getHexProof(keccak256(userAddress)),
                    {
                        value: await contract.cost(),
                    },
                );
            }
        } catch (e) {}
    }, []);

    return {
        whitelistMintToken,
    };
};

export default useUploadToBlockChain;
