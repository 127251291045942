import React, { memo } from 'react';
import cn from 'classnames';
import styles from './index.module.css';
import moment from 'moment';
export const HistoricalRecordItem = memo(({ historyRecord }) => {
    return (
        <div className={styles.blockTokenHistoryItemScrollWrapper}>
            <div className={styles.blockTokenHistoryItemScroll}>
                <div className={styles.blockTokenHistoryItem}>
                    <p className={styles.blockTokenHistoryEvent}>{historyRecord.event}</p>
                    <p className={styles.blockTokenHistoryPrice}>{historyRecord.price}</p>
                    <p className={styles.blockTokenHistorySender}>{historyRecord.sender}</p>
                    <p className={styles.blockTokenHistoryGet}>{historyRecord.reciever}</p>
                    <p className={styles.blockTokenHistoryDate}>
                        {moment(historyRecord.date).format('DD.MM.YYYY')}
                    </p>
                </div>
            </div>
        </div>
    );
});

