const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1`;

export const sendTariffCard = async (email, count) => {
    const response = await fetch(`${BASE_URL}/billing/tariff`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, count_certificates: count }),
    });
    const data = await response.json();
    return data;
};

export const sendTariffCash = async (email, count) => {
    return { isError: false };
};

export const sendTariffQr = async (email, count) => {
    return { isError: false };
};
export const sendTariffPayment = async (email, count) => {
    return { isError: false };
};

export const sendForm = async (
    userPhone,
    userName,
    brandType,
    mainTask,
    nameBrand,
    email,
    site,
) => {
    // Создаем объект с данными
    const requestData = {};

    // Добавляем данные в объект, только если они определены
    if (userPhone) requestData.name = userPhone;
    if (userName) requestData.phone = userName;
    if (brandType) requestData.brand_type = brandType;
    if (mainTask) requestData.main_task = mainTask;
    if (nameBrand) requestData.name_brand = nameBrand;
    if (email) requestData.email = email;
    if (site) requestData.site = site;

    // Отправляем запрос
    const response = await fetch(`${BASE_URL}/cooperation/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    });
    const status = response.status;
    const data = await response.json();
    return status < 400 ? data : null;
};
