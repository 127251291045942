export const changeUserInfo = (
    email,
    phone,
    telegram,
    username,
    whatsapp,
    instagram,
    userFullName,
    userAvatar,
    setVerifyData,
    setIsVerify,
) => {
    const accessToken = localStorage.getItem('access_token');
    const requestPayload = {};
    if (email.length > 0) {
        requestPayload.email = email;
    }
    if (phone.length > 0) {
        requestPayload.phone = phone.substring(1);
    }
    if (username.length > 0) {
        requestPayload.username = username;
    }
    if (whatsapp.length > 0) {
        requestPayload.whatsapp = whatsapp;
    }
    if (telegram.length > 0) {
        requestPayload.telegram = telegram;
    }
    if (instagram.length > 0) {
        requestPayload.instagram = instagram;
    }
    if (userFullName.length > 0) {
        requestPayload.first_name = userFullName;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        method: 'PUT',
        headers: {
            authorization: `Bearer ${accessToken}`,
            'content-type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
    })
        .then((res) => {
            return res.json();
        })
        .then((response) => {
            if (response) {
                if (response.phone && response.email) {
                    setIsVerify(true);
                    if (!response.email_verified && !response.phone_verified) {
                        setVerifyData({
                            type: 'all',
                            nextStep: 2,
                            contacts: {
                                phone: response.phone,
                                email: response.email,
                            },
                        });
                    }
                    if (response.email_verified && !response.phone_verified) {
                        setVerifyData({
                            type: 'phone',
                            nextStep: 3,
                            contacts: {
                                phone: response.phone,
                                email: null,
                            },
                        });
                    }
                    if (!response.email_verified && response.phone_verified) {
                        setVerifyData({
                            type: 'email',
                            nextStep: 3,
                            contacts: {
                                phone: null,
                                email: response.email,
                            },
                        });
                    }
                }
                if (response.phone && !response.email) {
                    if (!response.phone_verified) {
                        setIsVerify(true);
                        setVerifyData({
                            type: 'phone',
                            nextStep: 3,
                            contacts: {
                                phone: response.phone,
                                email: null,
                            },
                        });
                    }
                }
                if (!response.phone && response.email) {
                    if (!response.email_verified) {
                        setIsVerify(true);
                        setVerifyData({
                            type: 'email',
                            nextStep: 3,
                            contacts: {
                                phone: null,
                                email: response.email,
                            },
                        });
                    }
                }
            }
        });

    if (userAvatar) {
        const formData = new FormData();
        formData.append('image', userAvatar);
        fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my/add_image`,
            {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
                body: formData,
            },
        );
    }
};
