import React, { useState, useEffect, useMemo } from 'react';

import './index.css';
import AdminsItem from './AdminsItem';
import { Link } from 'react-router-dom';
import FilterTable from '../../components/FilterTable';
// import { fetchAllRoles } from './utils';
// import { TablePagination } from '../../components/Pagination/Pagination';
import { useDynamicPagination } from '../useDynamicPagination';

const Admins = () => {
    // const [isSearch, setIsSearch] = useState();
    const [refetch, setRefetch] = useState(false);
    const [edit, setEdit] = useState(false);
    const [search, setSearch] = useState('');

    const roles = useDynamicPagination(
        `${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/`,
        [
            {
                title: 'page_size',
                value: 20,
            },
            {
                title: 'q',
                value: search,
            },
        ],
        false,
        () => {},
        () => {},
    );

    // const getAccessToCollections = useMemo(() => {
    //     const accessList = roles.map((role) => {
    //         if (role.access && role.access.length > 0) {
    //             return role.access
    //         }
    //         return null;
    //     });
    //     return accessList;
    // }, [roles]);

    const handleSetSearchValue = (e) => {
        setRefetch(true);
        setSearch(e);
    };

    return (
        <section className="admin__padding admins">
            <div className="container">
                <div className="admins__inner">
                    <h1 className="title">Администраторы/модераторы</h1>

                    <FilterTable
                        setSearch={handleSetSearchValue}
                        search={search}
                        // button={
                        //     <Link
                        //         to="/admin/createadmin"
                        //         className="collection__get default__hover"
                        //     >
                        //         Create Admin / Moder
                        //     </Link>
                        // }
                        tokensCount="25 points"
                    >
                        <div className="stats__item--names whithdraw__names">
                            <div className="admins__item--item stats__item--name off700">
                                Статус
                            </div>

                            <div className="admins__item--item stats__item--name">Пользователь</div>

                            <div className="admins__item--item stats__item--name">Кошелек</div>

                            <div className="admins__item--item stats__item--name off700 activeoff1200">
                                Создатель
                            </div>

                            <div className="admins__item--item stats__item--name off998 activeoff1600">
                                Описание
                            </div>

                            <div className="admins__item--item stats__item--name off1200 activeoff1600">
                                Доступ
                            </div>
                        </div>

                        {roles &&
                            roles.items &&
                            roles.items.map((role, id) => (
                                <AdminsItem
                                    key={id}
                                    data={role}
                                    id={id + 1}
                                    access={role.access}
                                    setEdit={setEdit}
                                    isEdit={edit}
                                />
                            ))}
                    </FilterTable>
                </div>
            </div>
        </section>
    );
};

export default Admins;
