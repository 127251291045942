import React, { memo, useCallback, useEffect, useState } from 'react';
import './style.css';

export const RecievedToken = memo(({ tokensList, handleCloseModal, user }) => {
    const [index, setIndex] = useState(0);
    const accessToken = localStorage.getItem('access_token');
    const [error, setError] = useState('');

    const handleApproveTokens = useCallback(
        (id) => {
            if (id) {
                fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/user/me/${id}/`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            viewed: true,
                        }),
                    },
                ).then((res) => {
                    if (Number(res.status) <= 300) {
                        if (tokensList.results.length - 1 > index) {
                            setIndex((prev) => prev + 1);
                        }
                        if (tokensList.results.length - 1 === index) {
                            handleCloseModal();
                        }
                        setError('');
                    }

                    if (Number(res.status) >= 400) {
                        setError('При обработке токена произошла ошибка');
                    }
                });
            }
        },
        [user, tokensList, index],
    );

    return (
        tokensList.results &&
        tokensList.results.length > 0 &&
        index !== tokensList.results.length && (
            <div className="modalContainer">
                <div className="tokenTransferModal__container" key={tokensList.results[index].id}>
                    <div className="container__header">
                        <p style={{ color: 'white' }}>Поздравляем! Вам отправлен:</p>
                        <button className="header__button" onClick={handleCloseModal}>
                            ×
                        </button>
                    </div>
                    <div
                        style={{
                            padding: '25px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '25px',
                            alignItems: 'center',
                        }}
                    >
                        <p style={{ color: '#1767B7' }}>Цифровой сертификат</p>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                gap: '15px',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{ width: '70px', height: '70px', borderRadius: '6px' }}
                                src={tokensList.results[index].file_1}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div>
                                    <p style={{ color: '#1767B7' }}>
                                        {tokensList.results[index].collection.name}
                                    </p>
                                    <p style={{ color: 'white' }}>
                                        {tokensList.results[index].name}
                                    </p>
                                </div>

                                <div>
                                    <p style={{ color: 'white' }}>Стоимость</p>
                                    <p style={{ color: 'white' }}>
                                        {Math.floor(tokensList.results[index].price / 100)} RUB
                                    </p>
                                </div>
                            </div>
                        </div>
                        {error.length > 0 && <p style={{ color: 'red' }}>{error}</p>}
                        <button
                            className="modal__button"
                            onClick={() =>
                                handleApproveTokens(
                                    tokensList.results[index].id,
                                    // tokensList.results[index],
                                )
                            }
                        >
                            Получить
                        </button>
                    </div>
                </div>
            </div>
        )
    );
});
