import React from 'react';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Star2 from '../../../../../icons/Star2';
import Star from '../../../../../icons/Star';
import styles from '../../../index.module.css';
import base from '../../../../../styles/base.module.css';
import { getReviewWord } from '../../../lib/reviews';
import { connectWallet } from '../../../../../functions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { WalletModal } from '../../../../../components/HeaderNew/modal/Wallet';

export const AllInfoReview = ({ reviews, reviewsPrecent, starsPrecent, setReviewModal }) => {
    const isAuth = useSelector((state) => state.auth.isAuth);
    const dispatch = useDispatch();
    const connectTrust = async () => {
        return await connectWallet(dispatch);
    };

    const [connect, setConnect] = React.useState(false);

    const addReview = async () => {
        if (isAuth) {
            setReviewModal(true);
        } else {
            const isTrust = await connectTrust();

            if (!isTrust) {
                setConnect(true);
            } else {
                setReviewModal(true);
            }
        }
    };
    return (
        <>
            <div className={styles.reviewsInfoInner}>
                <div className={styles.reviewsInfo}>
                    <div
                        className={styles.reviewsInfoChart}
                        style={{
                            backgroundImage: `conic-gradient(#2081e2 ${reviewsPrecent.percentOfFive}%, #292733 ${reviewsPrecent.percentToFive}%`,
                        }}
                    >
                        <div className={styles.reviewsInfoChartValue}>
                            {reviews && reviews.statistics
                                ? Number(reviews.statistics.average_rating).toFixed(2) || 0
                                : 0}
                        </div>
                    </div>

                    <p className={styles.reviewsInfoTitle}>Общая оценка</p>

                    <div className={styles.reviewsInfoRateInner}>
                        <div className={styles.reviewsInfoRate}>
                            {Array.from({ length: 5 }, (_, index) =>
                                reviews &&
                                index < Math.round(reviews.statistics.average_rating || 0) ? (
                                    <Star2 key={index} />
                                ) : (
                                    <Star key={index} />
                                ),
                            )}
                        </div>

                        <p>
                            <span>{reviews ? reviews.statistics.total_reviews : 0}</span>{' '}
                            {getReviewWord(reviews ? reviews.statistics.total_reviews : 0)}
                        </p>
                    </div>

                    <div className={styles.reviewsInfoRateBlock}>
                        <div className={styles.reviewsInfoRateBlockItem}>
                            <div className={styles.reviewsInfoRateBlockItemContext}>
                                5
                                <Star2 />
                            </div>

                            <div className={styles.reviewsInfoRateBlockItemProgress}>
                                <div
                                    className={styles.reviewsInfoRateBlockItemBar}
                                    style={{ width: starsPrecent[5] }}
                                ></div>
                            </div>

                            <p className={styles.reviewsInfoRateBlockItemCount}>
                                <span> {reviews ? reviews.statistics.star_counts[5] : 0} </span>{' '}
                                {getReviewWord(reviews ? reviews.statistics.star_counts[5] : 0)}
                            </p>
                        </div>

                        <div className={styles.reviewsInfoRateBlockItem}>
                            <div className={styles.reviewsInfoRateBlockItemContext}>
                                4
                                <Star2 />
                            </div>

                            <div className={styles.reviewsInfoRateBlockItemProgress}>
                                <div
                                    className={styles.reviewsInfoRateBlockItemBar}
                                    style={{ width: starsPrecent[4] }}
                                ></div>
                            </div>

                            <p className={styles.reviewsInfoRateBlockItemCount}>
                                <span> {reviews ? reviews.statistics.star_counts[4] : 0} </span>{' '}
                                {getReviewWord(reviews ? reviews.statistics.star_counts[4] : 0)}
                            </p>
                        </div>

                        <div className={styles.reviewsInfoRateBlockItem}>
                            <div className={styles.reviewsInfoRateBlockItemContext}>
                                3
                                <Star2 />
                            </div>

                            <div className={styles.reviewsInfoRateBlockItemProgress}>
                                <div
                                    className={styles.reviewsInfoRateBlockItemBar}
                                    style={{ width: starsPrecent[3] }}
                                ></div>
                            </div>

                            <p className={styles.reviewsInfoRateBlockItemCount}>
                                <span> {reviews ? reviews.statistics.star_counts[3] : 0} </span>{' '}
                                {getReviewWord(reviews ? reviews.statistics.star_counts[3] : 0)}
                            </p>
                        </div>

                        <div className={styles.reviewsInfoRateBlockItem}>
                            <div className={styles.reviewsInfoRateBlockItemContext}>
                                2
                                <Star2 />
                            </div>

                            <div className={styles.reviewsInfoRateBlockItemProgress}>
                                <div
                                    className={styles.reviewsInfoRateBlockItemBar}
                                    style={{ width: starsPrecent[2] }}
                                ></div>
                            </div>

                            <p className={styles.reviewsInfoRateBlockItemCount}>
                                <span> {reviews ? reviews.statistics.star_counts[2] : 0} </span>{' '}
                                {getReviewWord(reviews ? reviews.statistics.star_counts[2] : 0)}
                            </p>
                        </div>

                        <div className={styles.reviewsInfoRateBlockItem}>
                            <div className={styles.reviewsInfoRateBlockItemContext}>
                                1
                                <Star2 />
                            </div>

                            <div className={styles.reviewsInfoRateBlockItemProgress}>
                                <div
                                    className={styles.reviewsInfoRateBlockItemBar}
                                    style={{ width: starsPrecent[1] }}
                                ></div>
                            </div>

                            <p className={styles.reviewsInfoRateBlockItemCount}>
                                <span> {reviews ? reviews.statistics.star_counts[1] : 0} </span>{' '}
                                {getReviewWord(reviews ? reviews.statistics.star_counts[1] : 0)}
                            </p>
                        </div>
                    </div>

                    <button
                        className={cn(base.orangeButton, styles.reviewsInfoButton)}
                        onClick={addReview}
                    >
                        Написать отзыв
                    </button>
                </div>
            </div>

            <WalletModal connect={connect} setConnect={setConnect} />
        </>
    );
};
