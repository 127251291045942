import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const UserAvatar = ({ style = 'default', avatar, icon, className }) => {
    return (
        <span className={cn(styles.ownersItemUserAvatar, styles[style], className,  {
            [styles.centerImage]: icon
        })}>
            <span>
                {icon ? icon : <img src={avatar} alt="img" />}
            </span>
        </span>
    );
};

export default UserAvatar;
