import React, { useCallback } from 'react';
// import AWS from 'aws-sdk';

// const s3Client = new AWS.S3({
//     endpoint: process.env.REACT_APP_BACKET_BASE_URL,
//     accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//     secretAccessKey: process.env.REACT_APP_SECRET_KEY,
//     region: process.env.REACT_APP_BACKET_REGION,
// });

const S3Context = React.createContext({});

const S3ContextProvider = (props) => {
    const { children } = props;

    const getImageBacketPath = useCallback((path) => {
        // const params = {
        //     Bucket: process.env.REACT_APP_BACKET_NAME,
        //     Key: path[0] === '/' ? path.slice(1) : path,
        //     Expires: 60,
        // };
        try {
            // return s3Client.getSignedUrl('getObject', params);
            return `${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${path}`;
        } catch (e) {
            return path;
        }
    }, []);

    const actions = {
        getImageBacketPath,
    };

    return (
        <S3Context.Provider
            value={{
                actions,
            }}
        >
            {children}
        </S3Context.Provider>
    );
};

export { S3Context, S3ContextProvider };
