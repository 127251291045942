import React from 'react';

import styles from './index.module.css';

const DescriptionBlock = ({ style, children }) => {
    return (
        <div className={styles.descriptionBlock}>
            <div className={styles.descriptionBlockWrapper} style={style}>{children}</div>
        </div>
    );
};

export default DescriptionBlock;
