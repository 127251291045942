export const fetchUserByToken = (setContacts, setUserProfile) => {
    const access_token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
        .then((res) => {
            if (Number(res.status) === 200) {
                return res.json();
            }
        })
        .then((response) => {
            if (response && !response.errors) {
                setUserProfile(response);
                if (response.phone) {
                    setContacts((prev) => ({
                        ...prev,
                        phone: response.new_phone ? response.new_phone : response.phone,
                    }));
                }
                if (response.email) {
                    setContacts((prev) => ({
                        ...prev,
                        email: response.new_email ? response.new_email : response.email,
                    }));
                }
            }
        });
};

export const handleFetchSMSCode = (code, setError, step) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/send_sms/${
        step === 1 ? code : code
    }`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    }).catch((error) => {
        setError(error.msg);
    });
};

export const handleFetchCodeByType = (type, code, setStep, nextStep, setError) => {
    const accessToken = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/confirm_${type}/${code}`;

    fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
    })
        .then((res) => {
            if (res.ok) {
                // if (type === 'email' && phoneCode) {
                //     handleFetchSMSCode(phoneCode, setError);
                // }
                setStep(nextStep);
                setError('');
            } else {
                return res.json();
            }
        })
        .then((response) => {
            if (response && response.msg) {
                setError(response.msg);
            }
        })
        .catch((error) => {
            setError('Произошла ошибка при обработке запроса.');
        });
};

export const handleUpdateUserContacts = (setVerifyStep, email, phone, nextStep, type, setError) => {
    const requestBodyTypes = {
        all: { email, phone: phone.substring(1) },
        phone: { phone: phone.substring(1) },
        email: { email },
    };

    console.log(requestBodyTypes[type])
    const accessToken = localStorage.getItem('access_token');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth_service/user/api/v1/profile/my`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(requestBodyTypes[type]),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response) {
                setVerifyStep(nextStep);
                setError('');
            }
        })
        .catch((err) => {
            setError(err.msg);
        });
};
