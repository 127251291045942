import React from 'react';

const AddFile = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.58579 1.25376C2.96086 0.878682 3.46957 0.667969 4 0.667969H9.66667C9.84348 0.667969 10.013 0.738207 10.1381 0.863231L13.8047 4.5299C13.9298 4.65492 14 4.82449 14 5.0013V13.3346C14 13.8651 13.7893 14.3738 13.4142 14.7488C13.0391 15.1239 12.5304 15.3346 12 15.3346H2.66667C2.29848 15.3346 2 15.0362 2 14.668C2 14.2998 2.29848 14.0013 2.66667 14.0013H12C12.1768 14.0013 12.3464 13.9311 12.4714 13.806C12.5964 13.681 12.6667 13.5114 12.6667 13.3346V5.27744L9.39052 2.0013H4C3.82319 2.0013 3.65362 2.07154 3.5286 2.19656C3.40357 2.32159 3.33333 2.49116 3.33333 2.66797V5.33464C3.33333 5.70283 3.03486 6.0013 2.66667 6.0013C2.29848 6.0013 2 5.70283 2 5.33464V2.66797C2 2.13754 2.21071 1.62883 2.58579 1.25376Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33464 0.667969C9.70283 0.667969 10.0013 0.966446 10.0013 1.33464V4.66797H13.3346C13.7028 4.66797 14.0013 4.96645 14.0013 5.33464C14.0013 5.70283 13.7028 6.0013 13.3346 6.0013H9.33464C8.96645 6.0013 8.66797 5.70283 8.66797 5.33464V1.33464C8.66797 0.966446 8.96645 0.667969 9.33464 0.667969Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33203 9.9987C1.33203 9.63051 1.63051 9.33203 1.9987 9.33203H5.9987C6.36689 9.33203 6.66536 9.63051 6.66536 9.9987C6.66536 10.3669 6.36689 10.6654 5.9987 10.6654H1.9987C1.63051 10.6654 1.33203 10.3669 1.33203 9.9987Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.9987 7.33203C4.36689 7.33203 4.66536 7.63051 4.66536 7.9987V11.9987C4.66536 12.3669 4.36689 12.6654 3.9987 12.6654C3.63051 12.6654 3.33203 12.3669 3.33203 11.9987V7.9987C3.33203 7.63051 3.63051 7.33203 3.9987 7.33203Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default AddFile;
