import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setUserTokenTransferStatus } from './slice/tokenSlice';

export const ApproveCancelModal = ({ element, handleCloseModal }) => {
    const userIsAuth = useSelector(
        (state) => state.tokensStatisticSlice.userTokenReciever,
        shallowEqual,
    );
    const dispatch = useDispatch();
    const successTransfer = useRef(null);
    const [status, setStatus] = useState({
        text: '',
        status: null,
    });
    const accessToken = localStorage.getItem('access_token');
    const handleDeleteToken = () => {
        if (element && element.token_transfer && element.token_transfer.id)
            fetch(
                `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/store/transfers/${element.token_transfer.id}/cancel/`,
                {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-type': 'application/json',
                    },
                },
            )
                .then((res) => {
                    if (Number(res.status) <= 250) {
                        setStatus({ text: 'Передача токена отменена!', status: 200 });
                    }
                    if (Number(res.status) >= 400) {
                        setStatus({ text: 'При отправке запроса произошла ошибка', status: 500 });
                    }
                })
                .catch((err) => {});
    };

    return (
        <div className="tokenTransferModal" ref={successTransfer}>
            <div className="tokenTransferModal__container">
                <div className="container__header">
                    <h3>Внимание</h3>
                    <button className="header__button" onClick={handleCloseModal}>
                        ×
                    </button>
                </div>

                <div className="container__content">
                    <p style={{ color: 'white', userSelect: 'none', textAlign: 'center' }}>
                        Подтверждение отмены передачи
                    </p>
                    {status.text.length !== 0 && (
                        <p
                            style={{
                                color: status.status === 200 ? 'green' : '#CC5554',
                                textAlign: 'center',
                            }}
                        >
                            {status.text}
                        </p>
                    )}
                    <div className="container__footer" style={{ display: 'flex', gap: '10px' }}>
                        {status.status === null ? (
                            <>
                                <button
                                    style={{
                                        backgroundColor: element.token_transfer.id
                                            ? '#CC5554'
                                            : '#bbbbbb',
                                    }}
                                    className="modal__button"
                                    onClick={() => handleDeleteToken()}
                                    disabled={!element.token_transfer.id}
                                >
                                    подтверждение
                                </button>
                                <button
                                    className="modal__button"
                                    onClick={() => handleCloseModal()}
                                >
                                    отмена
                                </button>
                            </>
                        ) : (
                            <button className="modal__button" onClick={() => handleCloseModal()}>
                                закрыть
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
