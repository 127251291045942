import React, { useState } from 'react';
import { ModalNew2 } from '../../components/ModalNew2';
import { DescriptionBlock } from '../../components/DescriptionBlock';
import { InputNew } from '../../common/InputNew';
import { PropertyItem } from '../../components/PropertyItem';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { memo } from 'react';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import Document from '../../icons/Document';
import { useSelector } from 'react-redux';
import { editToken, buyToken, handleSetTokenProps } from './utils';
const access_token = localStorage.getItem('access_token');
export const TokenFormalization = memo(
    ({ token, formalizationModal, setFormalizationModal, id }) => {
        const [orderAvto, setOrderAvto] = React.useState(false);
        const [orderAvtoStep, setOrderAvtoStep] = React.useState(1);
        const [tokenProps, setTokenProps] = useState([]);
        const [tokenPrice, setTokenPrice] = useState(0);
        const [newUserEmail, setNewuserEmai] = useState('');
        const [errors, setErrors] = useState([]);
        const [isCheckedOffer, setIsCheckedOffer] = useState(false);
        const [isCheckedPrivacy, setIsCheckedProvacy] = useState(false);
        function* handleFormalizeToken() {
            yield buyToken(id, newUserEmail, setErrors,setFormalizationModal);
        }

        const handleEditToken = () => {
            const generator = handleFormalizeToken();
            editToken(id, token, tokenProps, tokenPrice, access_token, generator, setErrors);
        };

        const handleCheckboxChange = (e, type) => {
            if (type === 'offer') {
                setIsCheckedOffer(e.target.checked);
            }
            if (type === 'privacy') {
                setIsCheckedProvacy(e.target.checked);
            }
        };

        return (
            <ModalNew2
                isSteps
                icon={orderAvtoStep === 1 && <Document />}
                value={formalizationModal}
                setValue={setFormalizationModal}
                title={`Оформление сертификата на автомобиль. Шаг ${orderAvtoStep} из 2`}
                step={orderAvtoStep}
                setSteps={setOrderAvtoStep}
            >
                <div className={styles.tokenModalOrderAvto}>
                    <p className={styles.tokenModalOrderAvtoTitle}>Цифровой сертификат</p>

                    {orderAvtoStep === 1 && (
                        <>
                            <div className={styles.tokenModalCard}>
                                <div className={styles.tokenModalCardImg}>
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${token.file_1}`}
                                        alt="token"
                                    />
                                </div>

                                <div className={styles.tokenModalInfo}>
                                    <div className={styles.tokenModalWrapperInfo}>
                                        <p className={styles.tokenModalCollectionName}>
                                            {token && token.collection ? token.collection.name : ''}
                                            e
                                        </p>

                                        <p className={styles.tokenModalTokenName}>{token.name}</p>
                                    </div>

                                    <div className={styles.tokenModalWrapper}>
                                        <p className={styles.tokenModalText}>
                                            Стоимость оформления:
                                        </p>

                                        <p className={styles.tokenModalTextPrice}>
                                            0.1% стоимости авто
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.tokenModalOrderAvtoItems}>
                                <InputNew
                                    setValue={(propValue) => setTokenPrice((propValue / 100) * 0.1)}
                                    title="Стоимость автомобиля"
                                    placeholder="Укажите стоимость автомобиля в рублях (9 900 000)"
                                />

                                <InputNew
                                    setValue={(propValue) =>
                                        handleSetTokenProps(
                                            token,
                                            setTokenProps,
                                            propValue,
                                            'Модель',
                                        )
                                    }
                                    title="Модель"
                                    placeholder="Введите название модели автомобиля (Lexus RX)"
                                />

                                <InputNew
                                    setValue={(propValue) =>
                                        handleSetTokenProps(
                                            token,
                                            setTokenProps,
                                            propValue,
                                            'Год выпуска',
                                        )
                                    }
                                    title="Год выпуска"
                                    placeholder="Укажите год выпуска автомобиля (2024)"
                                />

                                <InputNew
                                    setValue={(propValue) =>
                                        handleSetTokenProps(
                                            token,
                                            setTokenProps,
                                            propValue,
                                            'Цвет',
                                            4,
                                        )
                                    }
                                    title="Цвет"
                                    placeholder="Укажите цвет автомобиля (Белый)"
                                />

                                <InputNew
                                    setValue={(propValue) =>
                                        handleSetTokenProps(
                                            token,
                                            setTokenProps,
                                            propValue,
                                            'Объем (л)',
                                        )
                                    }
                                    title="Объем (л)"
                                    placeholder="Укажите объём двигателя в литрах (4,5)"
                                />

                                <InputNew
                                    setValue={(propValue) =>
                                        handleSetTokenProps(
                                            token,
                                            setTokenProps,
                                            propValue,
                                            'Номер',
                                        )
                                    }
                                    title="Номер"
                                    placeholder="Укажите гос.номер автомобиля (А 777 ААА 777)"
                                />
                            </div>

                            <button
                                className={cn(base.orangeButton, styles.tokenModalDescButton)}
                                onClick={() => setOrderAvtoStep(2)}
                            >
                                Продолжить
                            </button>
                        </>
                    )}

                    {orderAvtoStep === 2 && (
                        <>
                            <div className={styles.tokenModalCard}>
                                <div className={styles.tokenModalCardImg}>
                                    <img
                                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${token.file_1}`}
                                        alt="token"
                                    />
                                </div>

                                <div className={styles.tokenModalInfo}>
                                    <div className={styles.tokenModalWrapperInfo}>
                                        <p className={styles.tokenModalCollectionName}>
                                            {token && token.collection && token.collection.name}
                                        </p>

                                        <p className={styles.tokenModalTokenName}>{token.name}</p>
                                    </div>

                                    <div className={styles.tokenModalWrapper}>
                                        <p className={styles.tokenModalText}>
                                            Стоимость оформления:
                                        </p>

                                        <p className={styles.tokenModalTextPrice}>
                                            {tokenPrice.toFixed(0)} RUB
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.modalTokenInfoProps}>
                                {tokenProps.map((prop) => {
                                    return (
                                        <PropertyItem
                                            key={prop.id}
                                            title={prop.type}
                                            value={prop.name}
                                            // text="13 % имеют такое св-во"
                                        />
                                    );
                                })}
                            </div>

                            <InputNew
                                title="Адрес электронной почты"
                                placeholder="Введите ваш email"
                                setValue={(value) => setNewuserEmai(value)}
                            />

                            <div className={styles.modalTokenButtons}>
                                <button
                                disabled={!isCheckedOffer || !isCheckedPrivacy}
                                    className={cn(base.orangeButton, styles.modalTokenButton)}
                                    onClick={() => handleEditToken()}
                                >
                                    Продолжить
                                </button>

                                <button
                                    className={cn(
                                        base.orangeButton,
                                        styles.modalTokenButton,
                                        styles.disabled,
                                    )}
                                >
                                    Вернуться назад
                                </button>
                            </div>
                            <div>
                                {errors.map((err) => {
                                    return <p style={{ color: 'red' }}>{err}</p>;
                                })}
                            </div>
                            <div className={styles.modalTokenAgrees}>
                                <div className={styles.modalTokenAgree}>
                                    <input
                                        type="checkbox"
                                        className={styles.checkbox}
                                        id="offerAgree"
                                        checked={isCheckedOffer} // Привязываем значение состояния к чекбоксу
                                        onChange={(e) => handleCheckboxChange(e, 'offer')} // Обработчик изменений
                                    />

                                    <label
                                        htmlFor="offerAgree"
                                        className={styles.modalTokenAgreeLabel}
                                    >
                                        <img src="/assets/img/check5.svg" alt="check" />Я принимаю
                                        условия <Link to="/">Договора оферты</Link>
                                    </label>
                                </div>

                                <div className={styles.modalTokenAgree}>
                                    <input
                                        type="checkbox"
                                        className={styles.checkbox}
                                        id="policyAgree"
                                        checked={isCheckedPrivacy}
                                        onChange={(e) => handleCheckboxChange(e, 'privacy')}
                                    />

                                    <label
                                        htmlFor="policyAgree"
                                        className={styles.modalTokenAgreeLabel}
                                    >
                                        <img src="/assets/img/check5.svg" alt="check" />Я согласен
                                        на обработку моих персональных данных в соответствии с
                                        Политикой конфиденциальности
                                    </label>
                                </div>
                            </div>

                            <DescriptionBlock style={{ height: 150 }}>
                                <p>
                                    Уважаемый пользователь, чтобы завершить процесс токенизации и
                                    получить цифровой сертификат, пожалуйста, выполните следующие
                                    шаги:
                                </p>

                                <ol>
                                    <li>
                                        <strong>Укажите Ваш email:</strong> Введите адрес
                                        электронной почты, на который будет отправлена инструкция по
                                        активации сертификата.
                                    </li>
                                    <li>
                                        <strong>Произведите оплату:</strong> Следуйте указаниям на
                                        экране для завершения платежа. Убедитесь, что все данные
                                        введены корректно.
                                    </li>
                                    <li>
                                        <strong>Получите сертификат:</strong> После успешной оплаты
                                        Вы получите оформленный цифровой сертификат, который
                                        удостоверяет Ваше владение и поможет сформировать Ваш личный
                                        бренд на платформе.
                                    </li>
                                    <li>
                                        <strong>Проверьте почту:</strong> Вскоре после оплаты на
                                        указанный Вами email придёт письмо с инструкцией по
                                        активации сертификата. Пожалуйста, проверьте папку «Спам»,
                                        если письмо не пришло в течение нескольких минут.
                                    </li>
                                </ol>

                                <p>
                                    Если у Вас возникнут вопросы, не стесняйтесь обращаться в нашу
                                    службу поддержки.
                                </p>

                                <p>Спасибо за доверие!</p>
                            </DescriptionBlock>
                        </>
                    )}
                </div>
            </ModalNew2>
        );
    },
);
