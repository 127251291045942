import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import styles from '../index.module.css';

import Star2 from '../../../icons/Star2';
import Star from '../../../icons/Star';
import Check4 from '../../../icons/Check4';
import Cross from '../../../icons/Cross';

import { deleteReview } from '../lib/reviews';

import WhatsApp from '../../../icons/WhatsApp';
import Telegram2 from '../../../icons/Telegram2';
import Vk from '../../../icons/Vk';
import Instagram from '../../../icons/Instagram';
import Youtube from '../../../icons/Youtube';
import Twitter from '../../../icons/Twitter';
import Share from '../../../icons/Share';
import moment from 'moment';

const socialMediaIcons = {
    'whatsapp.com': <WhatsApp />,
    't.me': <Telegram2 />,
    'vk.com': <Vk />,
    'instagram.com': <Instagram />,
    'youtube.com': <Youtube />,
    'twitter.com': <Twitter />,
};

const getSocialMediaIcon = (url) => {
    try {
        // Если URL не начинается с http или https, добавляем протокол
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url;
        }

        const domain = new URL(url).hostname;

        for (const [key, icon] of Object.entries(socialMediaIcons)) {
            if (domain.includes(key)) {
                return icon;
            }
        }
    } catch (error) {
        console.error('Invalid URL', error);
    }
    return null;
};

const ReviewItem = ({ id, name, role, text, logo, stars, link, refechReview, date }) => {
    const userRole = useSelector((state) => state.auth.userRole);

    const handleDeleteReview = () => {
        const token = localStorage.getItem('access_token');
        fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/reviews/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (Number(res.status) === 204) {
                    refechReview();
                }
                if (Number(res.status) >= 400) {
                }
            })
            .catch((err) => {});

        //
    };
    return (
        <div className={styles.reviewsItemInner}>
            <div className={styles.reviewsItem}>
                <div className={styles.reviewsItemInfo}>
                    <div className={styles.reviewsItemInfoWrap}>
                        <p className={styles.reviewsItemInfoName}>
                            {link &&
                                link !== '' &&
                                (getSocialMediaIcon(link) ? (
                                    <a
                                        href={link}
                                        target="_blank"
                                        className={styles.brandItemPopupSocialLink}
                                    >
                                        {getSocialMediaIcon(link)}
                                    </a>
                                ) : (
                                    <a href={link} target="_blank" className={styles.brandItemSite}>
                                        <Share />
                                        {/* {link} */}
                                    </a>
                                ))}
                            {name}
                        </p>

                        <p className={styles.reviewsItemInfoRole}>{role}</p>

                        <div className={styles.reviewsItemInfoRate}>
                            {Array.from({ length: 5 }, (_, index) =>
                                index < stars ? <Star2 key={index} /> : <Star key={index} />,
                            )}
                        </div>
                    </div>

                    <div className={styles.reviewsItemAvatar}>
                        <img src={logo} alt="reviews" />
                    </div>
                </div>

                <p className={cn(styles.reviewsItemText)}>{text}</p>

                <div className={styles.reviewsItemButtons}>
                    <p className={styles.reviewsItemDate}>{moment(date).format('DD.MM.YYYY')}</p>

                    {userRole === 'admin' && (
                        <div className={styles.reviewsItemButtonsWrap}>
                            <button
                                className={cn(styles.reviewsItemButton, styles.danger)}
                                onClick={handleDeleteReview}
                            >
                                <Cross />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReviewItem;
