import React from 'react';

const Instagram2 = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            { ...props }
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 7.96983C3 5.22947 5.22947 3 7.96983 3H16.0302C18.7706 3 21 5.22947 21 7.96983V16.0302C21 18.7706 18.7706 21 16.0302 21H7.96983C5.22947 21 3 18.7706 3 16.0302V7.96983ZM8.42623 12C8.42623 13.9706 10.0294 15.5738 12 15.5738C13.9706 15.5738 15.5738 13.9706 15.5738 12C15.5738 10.0294 13.9706 8.42623 12 8.42623C10.0294 8.42623 8.42623 10.0294 8.42623 12ZM7.2679 12C7.2679 9.39066 9.39066 7.2679 12 7.2679C14.6093 7.2679 16.7321 9.39066 16.7321 12C16.7321 14.6093 14.6093 16.7321 12 16.7321C9.39066 16.7321 7.2679 14.6093 7.2679 12ZM17.7643 4.90884C18.4503 4.90884 19.0064 5.46493 19.0064 6.15089C19.0064 6.83688 18.4503 7.39297 17.7643 7.39297C17.0784 7.39297 16.5223 6.83688 16.5223 6.15089C16.5223 5.46493 17.0784 4.90884 17.7643 4.90884Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Instagram2;
