import React from 'react';
import cn from 'classnames';

import styles from './index.module.css';

const Tabs = ({ changeMobileTheme = false, small = false, children, className }) => {
    return <div className={cn(styles.tabsContent, className, {
        [styles.changeTheme]: changeMobileTheme,
        [styles.small]: small
    })}>{children}</div>;
};

export default Tabs;
