import React, { useContext, useEffect, useState } from 'react';
import FullDescription from '../../common/FullDescription';
import FilterItem from '../../components/FilterItem';

import { CustomSelect } from '../../common/CustomSelect';
import '../CreateService/index.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useGetServicesQuery,
    useGetAllAccountsQuery,
    useChangeServicesMutation,
    useGetAllCollectionQuery,
    useGetAllCompaniesQuery,
} from '../../redux/api/dataService';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { NotificationContext } from '../../context/NotificationContext';
import InputMask from 'react-input-mask';
import { TelegramMaskedInput } from '../../common/TelegramInput';

const sortList = [
    { value: 'pass', name: 'Pass to' },
    { value: 'vip', name: 'VIP Service' },
    { value: 'discount', name: 'Discount' },
    { value: 'gift', name: 'Gift' },
];

export const EditService = () => {
    const { serviceId } = useParams();
    const navigate = useNavigate();
    const [filter, setFilter] = React.useState('pass');
    const [filters, setFilters] = React.useState([]);
    const [filterActive, setFilterActive] = React.useState(false);
    const [filterData2, setFilterData2] = React.useState([]);
    const [values, setValues] = useState({
        company: '',
        type: 'pass_to',
        certificate_type: 'unique',
        manager_whatsapp: '',
        manager_telegram: 'https://t.me/',
        preview: '',
        description: '',
        manager_email: '',
        active: true,
        collections: [],
        contacts: [
            {
                country: '',
                state: '',
                city: '',
                address: '',
                site: '',
                phone: '',
                email: '',
                social: '',
            },
        ],
    });
    const [checkedItemsIds, setCheckedItemsIds] = useState([]);
    const [companyItems, setCompanyItems] = useState([]);
    const [collectionItems, setCollectionItems] = useState([]);

    const [updateService] = useChangeServicesMutation();
    const { data } = useGetServicesQuery({ id: serviceId });
    const { data: dataAccount } = useGetAllAccountsQuery({});
    const { data: dataCollection } = useGetAllCollectionQuery({});
    const { data: dataCompanies } = useGetAllCompaniesQuery({});

    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    const filterChange = (value) => {
        setFilter(value);
    };

    const onRemoveItem = (id) => {
        setCheckedItemsIds((prevState) => prevState.filter((el) => el !== id));
    };

    const onCheckItem = ({ id }) => {
        setCheckedItemsIds([...checkedItemsIds, id]);
        setValues({ ...values, company: checkedItemsIds });
    };

    const handleChangeCollection = (ids) => {
        setValues((prevState) => {
            const updatedValues = { ...prevState, collections: ids };
            return updatedValues;
        });
    };

    const handleCreateService = async () => {
        updateService({ body: values, id: serviceId }).then(() => {
            addNotification({
                type: NOTIFICATION_TYPES.SUCCESS,
                text: 'Service was modified',
            });
            navigate('/opportunities');
        });
    };

    useEffect(() => {
        if (dataAccount) {
            setFilterData2(
                dataAccount.results.map((item) => {
                    return {
                        text: item.name,
                        brand: item.logo,
                        id: item.id,
                    };
                }),
            );
        }
    }, [dataAccount]);

    // useEffect(() => {
    //   if(checkedItemsIds.length) {
    //     setValues({...values, collections: checkedItemsIds})
    //   }
    // }, [checkedItemsIds])

    useEffect(() => {
        if (data) {
            setValues({
                ...data,
                company: data.company.name,
                collections: data.collections.map((item) => item.id),
            });
            setCheckedItemsIds(data.collections.map((item) => item.id));
        }
    }, [data]);

    useEffect(() => {
        if (dataCompanies) {
            const items = dataCompanies.results.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                };
            });
            setCompanyItems(items);
        }
    }, [dataCompanies]);

    useEffect(() => {
        if (dataCollection) {
            const items = dataCollection.results.map((item) => {
                return {
                    name: item.name,
                    id: item.id,
                };
            });
            setCollectionItems(items);
        }
    }, [dataCollection]);

    useEffect(() => {
        if (values && values.company && dataCompanies) {
            const foundCompany = dataCompanies.results.filter(
                (item) => item.name === values.company,
            );

            if (foundCompany && foundCompany.length) {
                setValues({ ...values, company: foundCompany[0].id });
            }
        }
    }, [values, dataCompanies]);

    return (
        <div className="withdraw">
            <div className="container">
                <div className="withdraw__inner">
                    <h2 className="title collection__title">Edit service</h2>

                    <FullDescription
                        description="Fill in the fields and click Save. The service will be available to
                            users after moderation. Specify the NFT - collections whose owners will
                            be able to use the service. Specify the type of service, description,
                            contacts and other information. In case of providing a
                            low-quality/inconsistent with the service description, the service will
                            be deleted. It is forbidden to provide illegal services. Before
                            providing the service it is necessary to scan the QR code of the NFT
                            owner on his/her phone and verify the avatar of the NFT owner with the
                            client's face. Or ask the user to put your company logo on his/her
                            profile avatar and send you the link to his/her NFT. The service can
                            only be provided if there is a match. By posting your ad you agree to
                            the rules of the service."
                    />

                    <button
                        className="button service__show--collection"
                        onClick={() => setFilterActive(true)}
                    >
                        Choose collections
                        <span className="service__collection--count">8</span>
                    </button>

                    <div className="collection__content">
                        <div
                            className={`collection__filter--box service__filter${
                                filterActive ? ' active' : ''
                            }`}
                        >
                            <div className="collection__filter--title--box">
                                <p className="collection__filter--title">Filters</p>

                                <img
                                    src="/assets/img/cross2.svg"
                                    alt="cross"
                                    className="collection__filter--title--cross"
                                    onClick={() => setFilterActive(false)}
                                />
                            </div>

                            <FilterItem
                                title="Brands"
                                value={filterData2.length}
                                elements={filterData2}
                                setData={setFilterData2}
                                setFilters={setFilters}
                                filter
                                filters={filters}
                                checkedItemsIds={checkedItemsIds}
                                onRemoveItem={onRemoveItem}
                                onCheckItem={onCheckItem}
                            />

                            <div className="collection__filter--buttons">
                                <button className="button collection__filter--button--filter">
                                    Clear all
                                </button>

                                <button
                                    className="button collection__filter--button--filter blue__button"
                                    onClick={() => setFilterActive(false)}
                                >
                                    Done
                                </button>
                            </div>
                        </div>

                        <div className="collection__content--preitems">
                            <div className="collection__filter--active">
                                <div className="collection__filter--active--content">
                                    <button className="button collection__filter--active--item">
                                        <p className="collection__filter--active--item--text">
                                            Ready
                                        </p>

                                        <img
                                            src="/assets/img/cross2.svg"
                                            alt="cross"
                                            className="collection__filter--active--item--delete"
                                        />
                                    </button>
                                </div>

                                <button
                                    className="button collection__filter--clear"
                                    onClick={() => {
                                        setValues({
                                            company: '',
                                            type: 'pass_to',
                                            certificate_type: 'unique',
                                            preview: '',
                                            description: '',
                                            active: true,
                                            contacts: [
                                                {
                                                    country: '',
                                                    state: '',
                                                    city: '',
                                                    address: '',
                                                    site: '',
                                                    phone: '',
                                                    email: '',
                                                    social: '',
                                                },
                                            ],
                                        });
                                        setCheckedItemsIds([]);
                                    }}
                                >
                                    Clear All
                                </button>
                            </div>

                            <div className="service__content">
                                <p className="service__content--wrap--item--title mobile">
                                    Service description
                                </p>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">Company name</p>

                                    <div className="service__item--content">
                                        <CustomSelect
                                            optionsList={companyItems}
                                            value={values.company}
                                            onChange={(e) => {
                                                setValues((prevState) => ({
                                                    ...prevState,
                                                    company: e,
                                                }));
                                            }}
                                        />

                                        {/*<p className="service__item--input--char">*/}
                                        {/*  30 characters*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                                <div className="service__content">
                                    <p className="service__content--wrap--item--title mobile">
                                        Service description
                                    </p>

                                    <div className="service__item flexstart">
                                        <p className="service__item--title">Collections</p>

                                        <div className="service__item--content">
                                            <CustomSelect
                                                optionsList={collectionItems}
                                                value={values.collections}
                                                onChange={handleChangeCollection}
                                                multiple={true}
                                            />

                                            {/*<p className="service__item--input--char">*/}
                                            {/*  30 characters*/}
                                            {/*</p>*/}
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="service__item">*/}
                                {/*  <p className="service__item--title">*/}
                                {/*    Company logo*/}
                                {/*  </p>*/}

                                {/*  <div className="service__item--content">*/}
                                {/*    <File*/}
                                {/*      type="logosmall"*/}
                                {/*      id="createaccountLogo"*/}
                                {/*      value={logo}*/}
                                {/*      setValue={setLogo}*/}
                                {/*    />*/}
                                {/*  </div>*/}
                                {/*</div>*/}

                                <div className="service__item">
                                    <p className="service__item--title">Service</p>

                                    <div className="service__item--content">
                                        <div className="service__item--points">
                                            <div
                                                onClick={() =>
                                                    setValues({ ...values, type: 'pass_to' })
                                                }
                                                className={`service__item--point ${
                                                    values.type === 'pass_to' ? 'active' : ''
                                                }`}
                                            >
                                                Pass to
                                            </div>

                                            <div
                                                onClick={() =>
                                                    setValues({ ...values, type: 'vip_service' })
                                                }
                                                className={`service__item--point ${
                                                    values.type === 'vip_service' ? 'active' : ''
                                                }`}
                                            >
                                                VIP Service
                                            </div>

                                            <div
                                                onClick={() =>
                                                    setValues({ ...values, type: 'discount' })
                                                }
                                                className={`service__item--point ${
                                                    values.type === 'discount' ? 'active' : ''
                                                }`}
                                            >
                                                Discount
                                            </div>

                                            <div
                                                onClick={() =>
                                                    setValues({ ...values, type: 'gift' })
                                                }
                                                className={`service__item--point ${
                                                    values.type === 'gift' ? 'active' : ''
                                                }`}
                                            >
                                                Gift
                                            </div>
                                        </div>

                                        <div className="service__list--mobile">
                                            <CustomSelect
                                                optionsList={sortList}
                                                value={filter}
                                                placeholder="Select filter"
                                                onChange={filterChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="service__item">
                                    <p className="service__item--title">Certificate type</p>

                                    <div className="service__item--content">
                                        <div className="service__item--points">
                                            <div
                                                onClick={() =>
                                                    setValues({
                                                        ...values,
                                                        certificate_type: 'unique',
                                                    })
                                                }
                                                className={`service__item--point ${
                                                    values.certificate_type === 'unique'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                Unique
                                            </div>

                                            <div
                                                onClick={() =>
                                                    setValues({
                                                        ...values,
                                                        certificate_type: 'premium',
                                                    })
                                                }
                                                className={`service__item--point ${
                                                    values.certificate_type === 'premium'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                Premium
                                            </div>

                                            <div
                                                onClick={() =>
                                                    setValues({
                                                        ...values,
                                                        certificate_type: 'vip',
                                                    })
                                                }
                                                className={`service__item--point ${
                                                    values.certificate_type === 'vip'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                Vip
                                            </div>
                                        </div>

                                        <div className="service__list--mobile">
                                            <CustomSelect
                                                optionsList={sortList}
                                                value={filter}
                                                placeholder="Select filter"
                                                onChange={filterChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">Short description</p>

                                    <div className="service__item--content">
                                        <textarea
                                            value={values.preview}
                                            onChange={(e) =>
                                                setValues({ ...values, preview: e.target.value })
                                            }
                                            className="input service__item--textarea short"
                                            placeholder="e.g. Your face on the avatar of your NFT is a guaranteed pass to the best nightclub in New York."
                                        />

                                        <p className="service__item--input--char">100 characters</p>
                                    </div>
                                </div>

                                <div className="service__item flexstart">
                                    <p className="service__item--title">Full description</p>

                                    <div className="service__item--content">
                                        <textarea
                                            value={values.description}
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    description: e.target.value,
                                                })
                                            }
                                            className="input service__item--textarea long"
                                            placeholder="e.g. A premiere entertainment haven, Marquee Nightclub in New York City attracts celebrities, world-class DJs, and partygoers from all over the globe for some of the industry&rsquo;s most extravagant events. The original monumental 5,000-square-foot destination, Marquee offers an immersive party experience with extensive lighting, an elevated DJ booth, video, and laser systems to enhance the diverse musical talent headlining the venue."
                                        />

                                        <p className="service__item--input--char">500 characters</p>
                                    </div>
                                </div>

                                <div className="service__item">
                                    <p className="service__item--title">Active</p>

                                    <div className="service__item--content">
                                        <div className="service__item--points">
                                            <div
                                                onClick={() =>
                                                    setValues({ ...values, active: !values.active })
                                                }
                                                className={`service__item--point ${
                                                    values.active ? 'active' : ''
                                                }`}
                                            ></div>
                                        </div>

                                        <div className="service__list--mobile">
                                            <CustomSelect
                                                optionsList={sortList}
                                                value={filter}
                                                placeholder="Select filter"
                                                onChange={filterChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="service__content--wrap">
                                    <div className="service__content--wrap--item">
                                        <p className="service__content--wrap--item--title">
                                            Contacts
                                        </p>

                                        <div className="service__item">
                                            <p className="service__item--title">Site</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].site
                                                            ? values.contacts[0].site
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. www.taogroup.com"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    site: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="service__item">
                                            <p className="service__item--title">Manager telegram</p>

                                            <div className="service__item--content">
                                                <TelegramMaskedInput
                                                    value={values.manager_telegram}
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            manager_telegram: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">Manager whatsapp</p>

                                            <div className="service__item--content">
                                                <InputMask
                                                    maskChar={null}
                                                    mask="7\ 999 99 999 99"
                                                    placeholder={'7 922 31 207 35'}
                                                    className="input service__item--input"
                                                    value={values.manager_whatsapp}
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            manager_whatsapp: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="service__item">
                                            <p className="service__item--title">Manager email</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={values.manager_email}
                                                    className="input service__item--input"
                                                    placeholder="e.g. example@gmail.com"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            manager_email: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">Phone</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].phone
                                                            ? values.contacts[0].phone
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. 646.473.0202"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    phone: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">Email</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].email
                                                            ? values.contacts[0].email
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. marquee@taogroup.com"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    email: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">Social</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].social
                                                            ? values.contacts[0].social
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. www.twitter.com/marquee"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    social: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service__content--wrap--item">
                                        <p className="service__content--wrap--item--title">
                                            Service locations
                                        </p>

                                        <div className="service__item">
                                            <p className="service__item--title">Country</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].country
                                                            ? values.contacts[0].country
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. USA"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    country: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>

                                            <button className="button service__item--button">
                                                All countries
                                            </button>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">State</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].state
                                                            ? values.contacts[0].state
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. New York"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    state: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>

                                            <button className="button service__item--button">
                                                All states
                                            </button>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">City</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].city
                                                            ? values.contacts[0].city
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. New York"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    city: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>

                                            <button className="button service__item--button">
                                                All cities
                                            </button>
                                        </div>

                                        <div className="service__item">
                                            <p className="service__item--title">Address</p>

                                            <div className="service__item--content">
                                                <input
                                                    value={
                                                        values.contacts &&
                                                        values.contacts.length &&
                                                        values.contacts[0].address
                                                            ? values.contacts[0].address
                                                            : ''
                                                    }
                                                    className="input service__item--input"
                                                    placeholder="e.g. 289 10th Avenue"
                                                    onChange={(e) =>
                                                        setValues({
                                                            ...values,
                                                            contacts: [
                                                                {
                                                                    ...values.contacts[0],
                                                                    address: e.target.value,
                                                                },
                                                            ],
                                                        })
                                                    }
                                                />
                                            </div>

                                            <button className="button service__item--button">
                                                <img src="/assets/img/address.svg" alt="address" />
                                                Address
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className="button service__confirm"
                                    onClick={handleCreateService}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
