import React from 'react';

export const Video2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 20C16.1944 20 20 16.1944 20 11.5C20 6.80558 16.1944 3 11.5 3C6.80558 3 3 6.80558 3 11.5C3 16.1944 6.80558 20 11.5 20ZM10.7058 15.134L15.5 12.366C16.1667 11.9811 16.1667 11.0189 15.5 10.634L10.7058 7.86603C10.0391 7.48113 9.20577 7.96225 9.20577 8.73205L9.20577 14.2679C9.20577 15.0377 10.0391 15.5189 10.7058 15.134Z"
                fill="currentColor"
            />
        </svg>
    );
};
