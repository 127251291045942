import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_HANDLE_API_URL } from '../../const/http/API_URLS';
import { generateQuery } from '../../utils/http/generateQuery';

export const handleApi = createApi({
    reducerPath: 'handleApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_HANDLE_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const {
                auth: { accessToken },
            } = getState();

            headers.set('Authorization', `Bearer ${accessToken}`);

            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getAccountsListFiltered: builder.query({
            query: ({ page, pageSize, sort, pageId, query, queryIndex }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }

                if (queryIndex) {
                    queryObj.query_index = queryIndex;
                }

                if (sort) {
                    queryObj.sort_by = sort;
                }

                if (pageId) {
                    queryObj.page_id = pageId;
                }

                return {
                    url: `/account/${generateQuery(queryObj)}`,
                };
            },
        }),

        getAccount: builder.query({
            query: ({ id }) => {
                return {
                    url: `/account/${id}`,
                };
            },
        }),

        getPacksListFiltered: builder.query({
            query: ({ page, pageSize, sort, collectionId, query, pageId, queryIndex }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }
                if (queryIndex) {
                    queryObj.query_index = queryIndex;
                }

                if (sort) {
                    queryObj.sort = sort;
                }

                if (collectionId) {
                    queryObj.collection_id = collectionId;
                }
                if (pageId) {
                    queryObj.page_id = pageId;
                }

                return {
                    url: `/pack/${generateQuery(queryObj)}`,
                };
            },
        }),
        getFilteredSimplifiedAccounts: builder.query({
            query: ({ page, pageSize, sort, query, accountsIds }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }

                if (sort) {
                    queryObj.sort = sort;
                }

                return {
                    url: `/account/simplified/${generateQuery(queryObj)}`,
                };
            },
        }),
        getFilteredSimplifiedCollections: builder.query({
            query: ({ page, pageSize, sort, query, accountsIds }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }

                if (sort) {
                    queryObj.sort = sort;
                }

                if (accountsIds && accountsIds.length) {
                    queryObj.account_id = accountsIds;
                }

                return {
                    url: `/collection/simplified/${generateQuery(queryObj)}`,
                };
            },
        }),
        getFilteredCollections: builder.query({
            query: ({
                page = 1,
                pageSize = 1000,
                sort,
                query,
                accountsIds,
                pageId,
                queryIndex,
                paid,
                status,
            }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                // if (status) {
                //     queryObj.status = status
                // }

                if (query) {
                    queryObj.q = query;
                }
                if (queryIndex) {
                    queryObj.query_index = queryIndex;
                }

                if (sort) {
                    queryObj.sort_by = sort;
                }

                if (accountsIds && accountsIds.length) {
                    queryObj.account_id = accountsIds;
                }
                if (pageId) {
                    queryObj.page_id = pageId;
                }
                queryObj.paid = paid;
                return {
                    url: `/collection/${generateQuery(queryObj)}`,
                };
            },
        }),
        getFilteredTokens: builder.query({
            query: ({
                page,
                pageSize,
                sortBy,
                query,
                status,
                collection_ids,
                levels_stats_ids,
                properties_id,
                paid = false,
                pack_id,
                pageId,
                queryIndex,
                status_store,
            }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }

                if (sortBy) {
                    queryObj.sort_by = sortBy;
                }

                if (status && status.length) {
                    queryObj.status = status;
                }
                if (queryIndex) {
                    queryObj.query_index = queryIndex;
                }
                if (collection_ids && collection_ids.length) {
                    queryObj.collection_id = collection_ids;
                }
                if (pack_id) {
                    queryObj.pack_id = pack_id;
                }

                if (levels_stats_ids && levels_stats_ids.length) {
                    queryObj.levels_stats_id = levels_stats_ids;
                }

                if (properties_id && properties_id.length) {
                    queryObj.properties_id = properties_id;
                }
                if (pageId) {
                    queryObj.page_id = pageId;
                }

                if (status_store) {
                    queryObj.status_store = status_store;
                }
                queryObj.paid = paid;

                return {
                    url: `/token/${generateQuery(queryObj)}`,
                };
            },
        }),
        getHandlerToken: builder.query({
            query: ({ pack_id, collection_id }) => ({
                url: `/token/?pack_id=${pack_id}&collection_id=${collection_id}&?page=1&page_size=1000`,
            }),
        }),
        getFilteredPages: builder.query({
            query: ({ page, pageSize, sort, query, accountsIds }) => {
                const queryObj = {
                    page,
                    page_size: pageSize,
                };

                if (query) {
                    queryObj.q = query;
                }

                if (sort) {
                    queryObj.sort_by = sort;
                }

                if (accountsIds && accountsIds.length) {
                    queryObj.account_id = accountsIds;
                }

                return {
                    url: `/page/${generateQuery(queryObj)}`,
                };
            },
        }),
        getPages: builder.query({
            query: () => ({
                url: 'page/',
            }),
        }),
    }),
});

export const {
    useGetAccountsListFilteredQuery,
    useGetAccountQuery,
    useGetPacksListFilteredQuery,
    useLazyGetPacksListFilteredQuery,
    useGetFilteredSimplifiedCollectionsQuery,
    useGetFilteredSimplifiedAccountsQuery,
    useGetFilteredTokensQuery,
    useLazyGetFilteredTokensQuery,
    useLazyGetHandlerTokenQuery,
    useGetFilteredCollectionsQuery,
    useLazyGetFilteredCollectionsQuery,
    useGetPagesQuery,
    useLazyGetFilteredPagesQuery,
    useLazyGetAccountsListFilteredQuery,
} = handleApi;
