import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import './App.css';

import Wrapper from './common/Wrapper';
import WrapperNew from './common/WrapperNew';

import InitialDataWrapper from './containers/InitialDataContainer';
import { NotificationsContainer } from './containers/NotificationsContainer';
import { NotificationProvider } from './context/NotificationContext';
import { EditOneToken } from './pages/EditOneToken';
import ErrorBoundary from './errorBoundary';
import CenteredContainer from './common/CenteredContainer';
import Loader from './common/Loader';
import CreatePage from './pages/CreatePage';
import CreateAccount from './pages/CreateAccount';
import CreateCollection from './pages/CreateCollection';
import CreatePack from './pages/CreatePack';
import CreateItem from './pages/CreateItem';
import CreateService from './pages/CreateService';
import CreateWhitelist from './pages/CreateWhitelist';
import ControlPanel from './pages/ControlPanel';
import Admins from './pages/Admins';
import WhiteList from './pages/WhiteList';
import Activity from './pages/Activity';
// import Statistics from './pages/Statistics';
import Statistics2 from './pages/Statistics2';
import Delivery from './pages/Delivery';
import Factory from './pages/Factory';
import Store from './pages/Store';
import Treasure from './pages/Treasure';
import Administrator from './pages/Administrator';
import SuperAdministrator from './pages/SuperAdministrator';

import Main from './pages/Main';
import Collection from './pages/Collection';
import About from './pages/About';
// import Profile from './pages/Profile';
// import ProfileNew from './pages/ProfileNew';
import TokenCard from './pages/TokenCard';
import TemplatePage from './pages/TemplatePage';
import Rankings from './pages/Rankings';
import Whithdraw from './pages/Whithdraw';
import Opportunities from './pages/Opportunities';
import { S3ContextProvider } from './context/S3Context';

import TestModal from './pages/TestModal';
import CreateAdmin from './pages/CreateAdmin';
import Royalty from './pages/Royalty';
import Whitelistes from './pages/Whitelistes';
import Cbcoin from './pages/Cbcoin';
import Factorystores from './pages/Factorystores';
import CreateFactorystores from './pages/CreateFactorystores';

import Brand from './pages/Brand';
import { EditService } from './pages/EditService';
import { TokenSuccessBuy } from './pages/TokenSuccessBuy';
import { useDispatch, useSelector } from 'react-redux';
import { CreateCompany } from './pages/CreateCompany';
import { ConclusionLayout } from './pages/Сonclusions/ConclusionLayout';
import { debounce } from 'lodash';
import { updateWindowSize } from './redux/slices/windowSizeSlice';

import MainNew from './pages/MainNew';
import BrandsNew from './pages/BrandsNew';
import BrandNew from './pages/BrandNew';
import Ofer from './pages/DocPages/Ofer';
import Refund from './pages/DocPages/Refund';
import Privacy from './pages/DocPages/Privacy';
import Rules from './pages/DocPages/Rules';
import { RedirectByShortCode } from './pages/RedirectByShortCode/RedirectByShortCode';
import OwnersPage from './pages/Owners';
import ProfileNew from './pages/ProfileNew';
import CollectionNew from './pages/CollectionNew';
import TokenNew from './pages/TokenNew';

const App = () => {
    const dispatch = useDispatch();
    const myRole = useSelector((state) => state.auth.userRole);

    useEffect(() => {
        const handleResize = debounce(() => {
            dispatch(
                updateWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                }),
            );
        });

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <ErrorBoundary>
                <Suspense
                    fallback={
                        <CenteredContainer>
                            <Loader />
                        </CenteredContainer>
                    }
                >
                    <StyledEngineProvider>
                        <NotificationProvider>
                            <S3ContextProvider>
                                <NotificationsContainer>
                                    <InitialDataWrapper>
                                        <Routes>
                                            <Route
                                                path="/short/:code"
                                                element={<RedirectByShortCode />}
                                            />
                                            <Route element={<WrapperNew />}>
                                                <Route path="/" element={<OwnersPage />} />
                                                <Route path="brandsnew/*" element={<BrandsNew />} />
                                                <Route path="brandnew/:id" element={<BrandNew />} />
                                                <Route path="/rules" element={<Rules />} />
                                                <Route path="/ofer" element={<Ofer />} />
                                                <Route path="/refund" element={<Refund />} />
                                                <Route path="/privacy" element={<Privacy />} />
                                                {/* Новые страницы */}
                                                <Route path="/brands" element={<MainNew />} />
                                                {/* //Новые страницы */}
                                            </Route>
                                            <Route element={<WrapperNew headerWithBackground />}>
                                                {/* Новые страницы */}
                                                <Route path="profileNew" element={<ProfileNew />} />
                                                {/* <Route
                                                    path="collectionNew"
                                                    element={<CollectionNew />}
                                                /> */}
                                                <Route path="token/:id" element={<TokenNew />} />
                                                {/* Новые страницы */}
                                            </Route>
                                            <Route exact path="/" element={<Wrapper />}>
                                                <Route path="brand" element={<Brand />} />
                                                <Route path="about" element={<About />} />
                                                <Route path="profile" element={<ProfileNew />} />
                                                <Route path="rankings" element={<Rankings />} />
                                                <Route path="whithdraw" element={<Whithdraw />} />
                                                <Route
                                                    path="opportunities"
                                                    element={<Opportunities />}
                                                />
                                                <Route
                                                    path="collection/:id"
                                                    element={<CollectionNew />}
                                                />
                                                {/* <Route path="token/:id" element={<TokenCard />} /> */}
                                                <Route
                                                    path="token/:id/success"
                                                    element={<TokenSuccessBuy />}
                                                />
                                                <Route path="cbcoin" element={<Cbcoin />} />
                                                <Route path=":url" element={<TemplatePage />} />
                                            </Route>
                                            {myRole === 'admin' && (
                                                <Route
                                                    exac
                                                    path="/admin/"
                                                    element={
                                                        <Wrapper isAdminPage={myRole === 'admin'} />
                                                    }
                                                >
                                                    <Route index element={<ControlPanel />} />
                                                    <Route path="admins" element={<Admins />} />
                                                    <Route path="royalty" element={<Royalty />} />
                                                    <Route
                                                        path="factorystores"
                                                        element={<Factorystores />}
                                                    />
                                                    <Route
                                                        path="whitelistes"
                                                        element={<Whitelistes />}
                                                    />
                                                    <Route
                                                        path="whitelist"
                                                        element={<WhiteList />}
                                                    />
                                                    <Route path="activity" element={<Activity />} />
                                                    {/* <Route path="statistics/*" element={ <Statistics /> } /> */}
                                                    <Route
                                                        path="statistics2/*"
                                                        element={<Statistics2 />}
                                                    />
                                                    <Route
                                                        path="delivery/*"
                                                        element={<Delivery />}
                                                    />
                                                    <Route path="factory/*" element={<Factory />} />
                                                    <Route path="store/*" element={<Store />} />
                                                    <Route
                                                        path="createpage"
                                                        element={<CreatePage />}
                                                    />
                                                    <Route
                                                        path="changepage/:url"
                                                        element={<CreatePage isEdit />}
                                                    />
                                                    <Route
                                                        path="createaccount"
                                                        element={<CreateAccount />}
                                                    />
                                                    {/* <Route path="changeaccount/:id" element={ <CreateAccount isEdit /> } /> */}
                                                    <Route path="treasure" element={<Treasure />} />
                                                    <Route
                                                        path="administrator"
                                                        element={<Administrator />}
                                                    />
                                                    <Route
                                                        path="superadministrator"
                                                        element={<SuperAdministrator />}
                                                    />
                                                    <Route
                                                        path="conclusions/*"
                                                        element={<ConclusionLayout />}
                                                    />
                                                    <Route
                                                        path="createcollection"
                                                        element={<CreateCollection />}
                                                    />
                                                    {/* <Route
                            path="updatecollection/:id/:pageId"
                            element={ <CreateCollection isEdit /> }
                          /> */}
                                                    <Route path="modal" element={<TestModal />} />
                                                    <Route
                                                        path="createpack"
                                                        element={<CreatePack />}
                                                    />
                                                    <Route
                                                        path="createitem"
                                                        element={<CreateItem />}
                                                    />
                                                    <Route
                                                        path="createcompany"
                                                        element={<CreateCompany />}
                                                    />
                                                    <Route
                                                        path="createservice"
                                                        element={<CreateService />}
                                                    />
                                                    <Route
                                                        path="editservice/:serviceId"
                                                        element={<EditService />}
                                                    />
                                                    <Route
                                                        path="createadmin"
                                                        element={<CreateAdmin />}
                                                    />
                                                    <Route
                                                        path="createadmin/:roleId"
                                                        element={<CreateAdmin />}
                                                    />
                                                    <Route
                                                        path="createwhitelist"
                                                        element={<CreateWhitelist />}
                                                    />
                                                    <Route
                                                        path="createfactorystores"
                                                        element={<CreateFactorystores />}
                                                    />
                                                    <Route path="edit/">
                                                        <Route
                                                            path="page/:url"
                                                            element={<CreatePage isEdit />}
                                                        />
                                                        <Route
                                                            path="account/:id"
                                                            element={<CreateAccount isEdit />}
                                                        />
                                                        <Route
                                                            path="collection/:id"
                                                            element={<CreateCollection isEdit />}
                                                        />
                                                        <Route
                                                            path="pack/:id"
                                                            element={<CreatePack isEdit />}
                                                        />
                                                        <Route
                                                            path="pack/:id/:pageId/:collectionId/:accountId"
                                                            element={<CreatePack isEdit />}
                                                        />
                                                        <Route
                                                            path="token/:id"
                                                            element={<EditOneToken />}
                                                        />
                                                        <Route
                                                            path="token/:id/:pageId/:collectionId/:packId/:accountId"
                                                            element={<EditOneToken />}
                                                        />
                                                    </Route>
                                                    <Route path="*" element={<Navigate to="/" />} />
                                                </Route>
                                            )}
                                        </Routes>
                                    </InitialDataWrapper>
                                </NotificationsContainer>
                            </S3ContextProvider>
                        </NotificationProvider>
                    </StyledEngineProvider>
                </Suspense>
            </ErrorBoundary>
        </>
    );
};

export default App;
