import React from 'react';

const Pen = ({ ...props }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8766 3.81966L12.081 4.61524L10.3848 2.91898L11.1804 2.1234C11.3451 1.95881 11.6128 1.95893 11.7774 2.1234L12.8766 3.22261C13.0411 3.38721 13.0411 3.6551 12.8766 3.81966ZM4.03809 9.37807L3 12L5.6219 10.962C5.46784 10.6127 5.25157 10.2977 4.97692 10.0231C4.70224 9.74847 4.3873 9.53213 4.03809 9.37807ZM4.38376 8.92003C4.74919 9.09724 5.08113 9.33336 5.37389 9.62615C5.66666 9.91887 5.90281 10.2509 6.07998 10.6163L11.6841 5.01218L9.98785 3.31592L4.38376 8.92003Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Pen;
