import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';
import CertificatesItem from '../CertificatesItem';
import NotContent from '../../../../icons/NotContent';
import { useDynamicPagination } from '../../../useDynamicPagination';
import { expandListOfParameters } from '../../../utils';
// import CertificatesItemSkeleton from '../CertificatesItemSkeleton';

// import isElementIntoViewPort from '../../../../utils/isElementIntoViewPort';
// import {-
//     CertificateContext,
//     CertificateContextProvider,
// } from '../../lib/Certificate/CertificateContext';
// import { WhiteListContext, WhiteListContextProvider } from '../../lib/Certificate/WhiteListContext';
// import {
//     FiltersContext,
//     FiltersContextProvider,
//     SORT_BY_OPTIONS_ARR,
// } from '../../lib/Certificate/FiltersContext';

const url = `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/token/`;

export const Certificates = ({ setCount }) => {
    const [fullText, setFullText] = React.useState(false);
    const showText = (e) => {
        e.preventDefault();
        setFullText((prev) => !prev);
    };
    const [selectedCategory, setSelectedCategory] = React.useState('all');
    const [filter, setFilter] = React.useState('');
    const [isLoading, setIsLoadingCollection] = React.useState(true);
    const pages = useSelector((state) => state.pages.entities);

    const [description, setDescription] = React.useState(
        `Цифровые сертификаты - это новый прорыв в мире современных технологий. 
       Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям. 
       Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов. 
                    
       Коллекционирование таких сертификатов становится новым трендом, ведь собранную 
       коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`,
    );

    const [currentPage, setCurrentPage] = useState('brands');
    const accesToken = localStorage.getItem('access_token');
    const [resetList, setResetList] = useState(false);

    const params = useMemo(() => {
        return expandListOfParameters(
            [],
            [
                {
                    title: 'paid',
                    value: filter,
                },
                {
                    title: 'page_id',
                    value: selectedCategory !== 'all' ? selectedCategory : '',
                },
            ],
        );
    }, [filter, selectedCategory]);

    const { items } = useDynamicPagination(url, params, resetList, setResetList, () => {});

    // useEffect(() => {
    //     const params = new URLSearchParams();

    //     if (filter) {
    //         params.append('paid', filter);
    //     }

    //     if (selectedCategory !== 'all') {
    //         params.append('page_id', selectedCategory);
    //     }

    //     fetch(
    //         `${
    //             process.env.REACT_APP_BACKEND_URL
    //         }/handler_service/api/v1/token/?${params.toString()}`,
    //         {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${accesToken}`,
    //             },
    //         },
    //     )
    //         .then((res) => {
    //             return res.json();
    //         })
    //         .then((response) => {
    //             setTokens(response);
    //         })
    //         .catch((err) => {

    //         });
    // }, [selectedCategory, filter]);

    // const {
    //     state: { collectionTokensCount: count, collectionTokens },
    //     actions: { onNextPageCollectionsTokensHandler, handlePage, handleFilter },

    //     isTokensLoading,
    //     isFetchingLoading,
    // } = React.useContext(CertificateContext);

    // const {
    //     state: { whiteListApplication },
    //     actions: {
    //         onGetToWhitelistHandler,
    //         onBookTokenHandler,
    //         onDeleteWhiteListRequestHandler,
    //         onCancelHandler,
    //     },

    //     isWhitelistApplicationLoading,
    //     bookTokenIsLoading,
    // } = React.useContext(WhiteListContext);

    const containerRef = React.useRef(null);
    const firstUndrowenItemIdx = React.useRef(-1);

    // const isDataLoading = React.useMemo(() => {
    //     return !collectionTokens || isWhitelistApplicationLoading || isTokensLoading;
    // }, [collectionTokens, isWhitelistApplicationLoading, isTokensLoading, isFetchingLoading]);

    // React.useEffect(() => {
    //     setIsLoadingCollection(isDataLoading);
    // }, [isDataLoading]);

    // const onPageScrollHandler = React.useCallback(() => {
    //     if (containerRef.current && firstUndrowenItemIdx.current > -1) {
    //         const elem = Array.from(containerRef.current.childNodes)[firstUndrowenItemIdx.current];
    //         if (!isDataLoading && elem && isElementIntoViewPort(elem)) {
    //             onNextPageCollectionsTokensHandler();
    //             firstUndrowenItemIdx.current = -1;
    //         }
    //     }
    // }, [isDataLoading]);

    // React.useEffect(() => {
    //     window.addEventListener('scroll', onPageScrollHandler);

    //     return () => {
    //         window.removeEventListener('scroll', onPageScrollHandler);
    //     };
    // }, [isDataLoading]);

    React.useEffect(() => {
        // handlePage(selectedCategory);

        setDescription(
            selectedCategory == 'all'
                ? `Цифровые сертификаты - это новый прорыв в мире современных технологий.
                Они не только подтверждают подлинность и права владения, но и открывают доступ к эксклюзивным товарам, услугам и мероприятиям.
                Цифровые сертификаты могут служить в качестве членских билетов, пропусков, а также участвовать в различных розыгрышах призов.

                Коллекционирование таких сертификатов становится новым трендом, ведь собранную
                коллекцию можно в дальнейшем продать заинтересованным коллекционерам по выгодной цене.`
                : pages[selectedCategory].description,
        );
    }, [selectedCategory]);

    // React.useEffect(() => {
    //     handleFilter(filter);
    // }, [filter]);

    // React.useEffect(() => {
    //     !isTokensLoading && setCount(count);
    // }, [count]);

    return (
        <>
            <div className={styles.brandSubTabs}>
                {/* {isLoading ? (
                    <>
                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={styles.brandSubTabSkeletonWrapper}
                            className={styles.brandSubTabSkeleton}
                        />

                        <Skeleton
                            containerClassName={cn(styles.brandSubTabSkeletonWrapper, styles.small)}
                            className={styles.brandSubTabSkeleton}
                        />
                    </>
                ) : ( */}
                <>
                    <button
                        className={cn(styles.brandSubTab, {
                            [styles.active]: selectedCategory === 'all',
                        })}
                        onClick={() => {
                            setSelectedCategory('all');
                            setResetList(true);
                        }}
                    >
                        Все
                    </button>
                    {Object.values(pages).map((page) => (
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: selectedCategory === page.id,
                            })}
                            onClick={() => {
                                setSelectedCategory(page.id);
                                setResetList(true);
                            }}
                        >
                            {page.name}
                        </button>
                    ))}
                </>
                {/* )} */}
            </div>

            {/* {isLoading ? (
                <div className={styles.brandsText}>
                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />

                    <Skeleton
                        containerClassName={styles.loadingTextLineWrapper}
                        className={styles.loadingTextLine}
                    />
                </div> */}
            {/* ) : ( */}
            <div className={styles.brandsText} style={{ whiteSpace: 'pre-line' }}>
                <p>
                    {description && description.length > 150 && !fullText
                        ? `${description.slice(0, 150)}...`
                        : description}
                </p>

                {description && description.length > 150 && (
                    <a
                        href="#"
                        className={styles.brandsTextShow}
                        onClick={showText}
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {fullText ? 'Свернуть' : 'Развернуть'}
                    </a>
                )}
            </div>
            {/* )} */}

            <div className={styles.brandOuter}>
                <div className={styles.brandSubTabs}>
                    {/* {isLoading ? (
                        <>
                            <Skeleton
                                containerClassName={cn(
                                    styles.brandSubTabSkeletonWrapper,
                                    styles.small,
                                )}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />

                            <Skeleton
                                containerClassName={styles.brandSubTabSkeletonWrapper}
                                className={styles.brandSubTabSkeleton}
                            />
                        </>
                    ) : ( */}
                    <>
                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === null,
                            })}
                            onClick={() => {
                                setFilter(null);
                                setResetList(true);
                            }}
                        >
                            Все
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === true,
                            })}
                            onClick={() => {
                                setFilter(true);
                                setResetList(true);
                            }}
                        >
                            Продано
                        </button>

                        <button
                            className={cn(styles.brandSubTab, {
                                [styles.active]: filter === false,
                            })}
                            onClick={() => {
                                setFilter(false);
                                setResetList(true);
                            }}
                        >
                            В продаже
                        </button>
                    </>
                    {/* )} */}
                </div>

                {/* {isLoading ? (
                    <Skeleton
                        containerClassName={styles.tokenValueSkeletonWrapper}
                        className={styles.tokenValueSkeleton}
                    />
                ) : (
                    <p className={styles.tokenValue}>{count} шт.</p>
                )} */}
            </div>

            <div className={cn(styles.brandsContent, styles.certificateContent)} ref={containerRef}>
                {items &&
                    items.length > 0 &&
                    items.map((certificate) => (
                        <CertificatesItem key={certificate.id} certificate={certificate} />
                    ))}

                {/* {isLoading ? (
                    <>
                        {' '}
                        <CertificatesItemSkeleton /> <CertificatesItemSkeleton />{' '}
                        <CertificatesItemSkeleton /> <CertificatesItemSkeleton />{' '}
                        <CertificatesItemSkeleton /> <CertificatesItemSkeleton />{' '}
                        <CertificatesItemSkeleton /> <CertificatesItemSkeleton />
                    </>
                ) : (
                    <>
                        {collectionTokens &&
                            collectionTokens.map((certificate) => (
                                <CertificatesItem key={certificate.id} certificate={certificate} />
                            ))}
                        {collectionTokens.length > 0 &&
                            count - collectionTokens.length > 0 &&
                            Array.from(Array(count - collectionTokens.length).keys()).map((idx) => {
                                if (firstUndrowenItemIdx.current === -1) {
                                    firstUndrowenItemIdx.current = idx + collectionTokens.length;
                                }
                                return <CertificatesItemSkeleton />;
                            })}
                    </>
                )} */}
            </div>
            {items && items.length === 0 && (
                <div className={styles.notContentWrapper}>
                    <div className={styles.notContent}>
                        <NotContent />

                        <p className={cn(base.title2, styles.notContentTitle)}>
                            Нет информации
                            <br />
                            для отображения
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export const CertificatesContext = ({ setCount }) => {
    // const { id } = useParams();

    return (
        // <FiltersContextProvider>
        //     <CertificateContextProvider id={id}>
        //         <WhiteListContextProvider>
        <Certificates />
        //         </WhiteListContextProvider>
        //     </CertificateContextProvider>
        // </FiltersContextProvider>
    );
};
