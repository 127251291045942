import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setUserTokenTransferStatus } from './slice/tokenSlice';

export const ApproveCancelModal = ({ element, type, handleCloseModal }) => {
    const userIsAuth = useSelector(
        (state) => state.tokensStatisticSlice.userTokenReciever,
        shallowEqual,
    );
    const dispatch = useDispatch();
    const successTransfer = useRef(null);

    const handleDeleteToken = () => {
        if (type === 'sell') {
        }
        if (type === 'transfer') {
        }
    };

    return (
        <div className="tokenTransferModal" ref={successTransfer}>
            <div className="tokenTransferModal__container">
                <div className="container__header">
                    <h3>Внимание</h3>
                    <button className="header__button" onClick={handleCloseModal}>
                        ×
                    </button>
                </div>

                <div className="container__content">
                    <p style={{ color: 'white', userSelect: 'none', textAlign: 'center' }}>
                        Подтверждение отмены {type === 'sell' ? 'продажи' : 'передачи'}
                    </p>

                    <div className="container__footer" style={{ display: 'flex', gap: '10px' }}>
                        <button
                            style={{ backgroundColor: '#CC5554' }}
                            className="modal__button"
                            onClick={() => handleDeleteToken()}
                        >
                            подтверждение
                        </button>
                        <button
                            className="modal__button"
                            onClick={() => handleCloseModal((prev) => false)}
                        >
                            отмена
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
