import React from 'react';

const WhatsApp2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.48274 20.3864C3.44226 20.5559 3.49262 20.7351 3.62197 20.8578C3.71774 20.9503 3.84413 21 3.97544 21C4.01889 21 4.06134 20.9951 4.10182 20.9854L8.21439 19.9306C9.40516 20.477 10.6759 20.7536 12.0009 20.7536C16.9625 20.7536 21 16.772 21 11.8768C21 6.98261 16.9607 3 11.9991 3C7.03749 3 3 6.98261 3 11.8768C3 13.5676 3.47986 15.2029 4.39319 16.6219L3.48274 20.3864ZM8 7.92469L8.65166 7.31402C8.8995 7.08222 9.29147 7.09196 9.52647 7.33642L10.9947 8.86262C11.2297 9.10709 11.2198 9.49374 10.972 9.72554L10.3302 10.3294C10.3302 10.3294 9.48104 10.8329 11.2603 12.5286L11.3334 12.6055C12.9586 14.4454 13.5125 13.635 13.5125 13.635L14.1543 13.0312C14.4022 12.7994 14.7942 12.8091 15.0292 13.0536L16.4727 14.5525C16.7077 14.7969 16.6978 15.1836 16.45 15.4154L15.7983 16.0261C15.3007 16.4926 14.6026 16.7069 13.9302 16.5695C13.0179 16.3855 11.6523 15.7465 9.81965 13.9485C9.8552 13.9885 9.89075 14.0255 9.92629 14.0683L9.70413 13.8385C9.74165 13.8784 9.78016 13.9135 9.81768 13.9505C8.09174 12.0552 7.5151 10.677 7.37587 9.76917C7.27713 9.09422 7.50234 8.39122 8 7.92469Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default WhatsApp2;
