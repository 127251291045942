import React from 'react';

import styles from './index.module.css';

const SocialLink = ({ children, href = '#', onClick = () => {} }) => {
    return (
        <a href={href} className={styles.profileSocialLink} onClick={() => onClick()}>
            {children}
        </a>
    );
};

export default SocialLink;
