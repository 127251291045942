import { ethers } from 'ethers';
import { auth } from '../api';
import { getProfile } from './user';
// import Web3 from 'web3';
import {
    setIsAuth,
    setLoadAuth,
    initWalletAddress,
    initBalance,
    initAccessToken,
    initRefreshToken,
    initId,
    initUsername,
    initImage,
    initCreated,
    initEmail,
    setIsWelcomeModalActive,
    setAllUserData,
    setUserRole,
    setInstagram,
    setWhatsapp,
    setTelegram,
} from '../redux/slices/auth';

const initUserRole = async (dispatch) => {
    const accessToken = localStorage.getItem('access_token') || '';

    if (accessToken) {
        const url = `${process.env.REACT_APP_BACKEND_URL}/auth_service/api/v1/users_roles/my`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${encodeURIComponent(accessToken)}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.permissions.length && data.permissions[0] !== null) {
            dispatch(setUserRole('admin'));
        }
    }
};

// Авторизация(нажатие на кнопку connect wallet)
export const connectWallet = async (dispatch) => {
    try {
        const signer = await getSigner();

        const address = await signer.getAddress();
        const balance = await signer.getBalance();
        const nonce = await getNonce(address);
        const signature = await signer.signMessage(`I am signing my one-time nonce:${nonce}`);
        const tokens = await getTokens(address, signature);
        const profile = await getProfileData(tokens['access_token']);

        const {
            public_address,
            id,
            username,
            image_url,
            created,
            email,
            instagram,
            whatsapp,
            telegram,
            email_verified,
        } = profile.data;

        localStorage.setItem('access_token', tokens['access_token']);
        localStorage.setItem('refresh_token', tokens['refresh_token']);

        dispatch(setAllUserData(profile.data));
        dispatch(setIsAuth(true));
        dispatch(initBalance(getNormalBalance(balance)));
        dispatch(initAccessToken(tokens['access_token']));
        dispatch(initRefreshToken(tokens['refresh_token']));
        dispatch(initWalletAddress(public_address));
        dispatch(initId(id));
        email && email_verified && dispatch(initEmail(email));
        dispatch(initUsername(username));
        dispatch(initImage(image_url));
        dispatch(initCreated(created));
        (!email || !email_verified) && dispatch(setIsWelcomeModalActive(true));
        email && email_verified && (window.location.href = '/profile');
        instagram && dispatch(setInstagram(instagram));
        whatsapp && dispatch(setWhatsapp(whatsapp));
        telegram && dispatch(setTelegram(telegram));
        await initUserRole(dispatch);
        return true;
    } catch (error) {}
};

const parseJWT = (token) => Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;

// Получить signer
export const getSigner = async () => {
    const isMobileDevice = /(iPhone|iPad|iPod|Android)/.test(navigator.userAgent);
    const dappUrl = 'checkbrand.site';
    const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        const isExpired = parseJWT(accessToken);
        if (!isExpired) {
            return;
        }
    }

    let provider;

    if (window.ethereum) {
        if (window.ethereum.isMetaMask) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
        }
    }

    if (isMobileDevice && !window.ethereum) {
        window.location.href = metamaskAppDeepLink;
    }

    if (provider) {
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        return signer;
    }
};

// export const getSigner = async () => {

//     let provider;

//     if (window.ethereum) {
//         if (window.ethereum.isTrust) {
//             // provider = new ethers.providers.Web3Provider(window.ethereum);
//             provider = new Web3(window.ethereum);
//         }
//     }

//     if (provider) {
//         await provider.send('eth_requestAccounts', []);
//         const signer = provider.getSigner();
//         return signer;
//     } else {
//         // Обработка случая, когда провайдер не инициализирован
//         throw new Error('Не удалось инициализировать провайдер');
//     }
// };

// Проверка авторизации время от времени, после истекания срока токенов - авторизуемся заново, незаметно для пользователя
export const checkAuth = async (dispatch) => {
    dispatch(setLoadAuth(true));
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
        const profile = await getProfileData(accessToken);

        if (profile.data) {
            const {
                public_address,
                id,
                username,
                image_url,
                created,
                email,
                instagram,
                whatsapp,
                telegram,
                email_verified,
            } = profile.data;

            dispatch(setAllUserData(profile.data));

            dispatch(setIsAuth(true));
            dispatch(initAccessToken(accessToken));
            dispatch(initRefreshToken(refreshToken));
            dispatch(initWalletAddress(public_address));
            dispatch(initId(id));
            dispatch(initUsername(username));
            email && email_verified && dispatch(initEmail(email));
            dispatch(initImage(image_url));
            dispatch(initCreated(created));
            instagram && dispatch(setInstagram(instagram));
            whatsapp && dispatch(setWhatsapp(whatsapp));
            telegram && dispatch(setTelegram(telegram));
            const signer = await getSigner();
            const balance = await getBalance(signer);
            dispatch(initBalance(getNormalBalance(balance)));
            dispatch(setLoadAuth(false));
            await initUserRole(dispatch);
        } else {
            const newTokens = await auth.post(
                'refresh',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                },
            );

            localStorage.setItem('access_token', newTokens.data['access_token']);
            localStorage.setItem('refresh_token', newTokens.data['refresh_token']);

            await checkAuth(dispatch);
        }
    } else {
        dispatch(setLoadAuth(false));
        clearData(dispatch);
    }
};

// Получаем данные профиля
export const getProfileData = async (token) => {
    try {
        const profile = await getProfile(token);

        return profile;
    } catch (error) {
        return error;
    }
};

// Получаем баланс
export const getBalance = async (signer) => {
    if (!signer) {
        console.error('Signer is null or undefined');
        return null;
    }

    const balance = await signer.getBalance();

    return balance;
};

// Получаем нормальный баланс(в eth)
export const getNormalBalance = (balance) => {
    if (balance === null) {
        // Обработка случая, когда balance равен null
        console.error('Balance is null');
        return null;
    }

    return parseFloat(ethers.utils.formatEther(balance));
};

// Получаем nonce
export const getNonce = async (walletAddress) => {
    const nonce = await auth.get(`web3/nonce?public_address=${walletAddress}`);

    return nonce.data.nonce;
};

// Получаем refresh и access токены
export const getTokens = async (walletAddress, signature) => {
    const tokens = await auth.post('web3/login', {
        public_address: walletAddress,
        signature: signature,
    });

    return tokens.data;
};

// Выход с аккаунта
export const logout = async (token, dispatch) => {
    const result = await auth.post(
        'logout',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
    window.location.replace('/');

    clearData(dispatch);

    return result;
};

// Получить короткий адрес кошелька - xxxx...xxxx
export const getShortAddress = (address) => {
    return address.substr(0, 4) + '...' + address.substr(-4);
};

// Очистить данные в redux и localstorage после выхода из аккаунта
export const clearData = (dispatch) => {
    dispatch(setIsAuth(false));

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    dispatch(initAccessToken(''));
    dispatch(initRefreshToken(''));
    dispatch(initWalletAddress(''));
    dispatch(initId(''));
    dispatch(initUsername(''));
    dispatch(initEmail(''));
    dispatch(initImage(''));
    dispatch(initBalance(0));
};
