import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

import { FadeDescription } from '../../components/FadeDescription';
import Download2 from '../../icons/Download2';
import { SocialBlock, SocialLink } from '../../components/SocialBlock';
import Telegram2 from '../../icons/Telegram2';
import Instagram2 from '../../icons/Instagram2';
import Web2 from '../../icons/Web2';
import Vk2 from '../../icons/Vk2';
import Cross from '../../icons/Cross';
import CertificatesItem from '../BrandsNew/components/CertificatesItem';
import Reload from '../../icons/Reload';
import Filter from '../../icons/Filter';
import { Tab, Tabs } from '../../common/Tabs';
import ViewSmall from '../../icons/ViewSmall';
import ViewMedium from '../../icons/ViewMedium';
import ViewBig from '../../icons/ViewBig';
import { CustomSelect } from '../../common/CustomSelect';
import { SORT_BY_OPTIONS_ARR } from '../Collection/lib/FiltersContext';
import { FilterItem, FilterItemList } from '../../components/FilterItemList';
import Search2 from '../../icons/Search2';
import { Video2 } from '../../icons/Video2';
import { Plus2 } from '../../icons/Plus2';
import { Minus } from '../../icons/Minus';
import { Search3 } from '../../icons/Search3';
import CollectionNewSkeletonPage from './CollectionNewSkeletonPage';
import { useParams } from 'react-router-dom';
import { useDynamicPagination } from '../useDynamicPagination';
import { ShortenNumber } from '../../utils/ShortenNumber';

const windowWidth = window.innerWidth;
const device = windowWidth <= 480 ? 'mobile' : windowWidth <= 768 ? 'tablet' : 'desktop';

const generatePaidState = (paid) => {
    if (paid.paid && !paid.posted) {
        return true;
    }
    if ((!paid.paid && !paid.posted) || (paid.paid && paid.posted)) {
        return null;
    }
    if (!paid.paid && paid.posted) {
        return false;
    }
};


const CollectionNew = () => {
    const { id } = useParams();
    const [collection, setCollection] = useState(null);
    const [resetTokensList, setResetTokensList] = useState(false);
    const [paid, setPaid] = useState({ paid: false, posted: false });
    const [searchValue, setSearchValue] = useState('');

    const [sortBy, setSortBy] = useState('random_score');
    useEffect(() => {
        if (id) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/collection/${id}`)
                .then((res) => {
                    return res.json();
                })
                .then((response) => {
                    setCollection(response);
                })
                .catch((err) => {});
        }
    }, []);

    const { items } = useDynamicPagination(
        `${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/token/`,
        [
            {
                title: 'collection_id',
                value: id,
            },
            {
                title: 'paid',
                value: generatePaidState(paid),
            },
            {
                title: 'sort_by',
                value: sortBy,
            },
            {
                title: 'q',
                value: searchValue,
            },
        ],
        resetTokensList,
        setResetTokensList,
        () => {},
    );

    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const [filterOpen, setFilterOpen] = React.useState(device === 'desktop' ? true : false);
    const [showSearchFilter, setShowSearchFilter] = React.useState(false);
    const [view, setView] = React.useState(device === 'desktop' ? 'big' : 'normal');
    // const [skeleton, setSkeleton] = React.useState(true);
    const componentRef = React.useRef(null);
    const componentRef2 = React.useRef(null);
    const buttonRef = React.useRef(null);

    // React.useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (
    //             componentRef.current &&
    //             !componentRef.current.contains(event.target) &&
    //             componentRef2.current &&
    //             !componentRef2.current.contains(event.target) &&
    //             buttonRef.current &&
    //             !buttonRef.current.contains(event.target)
    //         ) {
    //             setShowSearchFilter(false);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);

    //     setTimeout(() => {
    //         setSkeleton(false);
    //     }, 1000);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    // if (skeleton) {
    //     return (
    //         <div className={base.marginWrapper}>
    //             <CollectionNewSkeletonPage />
    //         </div>
    //     );
    // }

    const handleSetPaidFilter = (type) => {
        setPaid((prev) => ({ ...prev, [type]: !paid[type] }));
        setResetTokensList(true);
    };

    const handleSortList = (value) => {
        setSortBy(value);
        setResetTokensList(true);
    };
    console.log(collection);
    //     items_count
    // :
    // 16
    // link_instagram
    // :
    // ""
    // link_telegram
    // :
    // ""
    // link_vk
    // :
    // ""
    // link_yoursite
    // :
    // ""
    // link_youtube
    // :
    // "
    return collection ? (
        <div className={base.marginWrapper}>
            <div className={styles.collectionCoverInner}>
                <div className={styles.collectionBg}>
                    <img
                        src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${collection.banner}`}
                        alt="collection bg"
                    />
                </div>

                <div className={base.container}>
                    <div className={styles.collectionImgInner}>
                        <img
                            src={`${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${collection.logo}`}
                            alt="collection img"
                        />
                    </div>
                </div>
            </div>

            <section className={styles.collection}>
                <div className={base.container}>
                    <div className={styles.collectionInner}>
                        <div className={styles.collectionInfoInner}>
                            <div className={styles.collectionInfoWrapper}>
                                <h1 className={styles.collectionInfoName}>{collection.name}</h1>

                                <div className={styles.collectionInfoPoints}>
                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {ShortenNumber(collection.profit)}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>
                                            объем продаж, руб
                                        </p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {ShortenNumber(collection.floor_price_count)}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>
                                            мин. цена, руб
                                        </p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {collection.items_count}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>токенов</p>
                                    </div>

                                    <div className={styles.collectionInfoPoint}>
                                        <p className={styles.collectionInfoPointValue}>
                                            {collection.owners_count}
                                        </p>
                                        <p className={styles.collectionInfoPointName}>владельцев</p>
                                    </div>
                                </div>

                                <FadeDescription
                                    theme="white"
                                    description={`В современном обществе токенизация становится настоящим трендом, позволяя вам не только инвестировать в предметы роскоши токенизация становится настоящим трендом, позволяя вам не только инвестировать в предметы`}
                                />
                            </div>

                            <div className={styles.collectionInfoSocial}>
                                <SocialBlock className={styles.collectionInfoSocialContent}>
                                    <SocialLink
                                        onClick={() => window.open(collection.link_yoursite)}
                                    >
                                        <Web2 />
                                    </SocialLink>

                                    <SocialLink
                                        onClick={() => window.open(collection.link_telegram)}
                                    >
                                        <Telegram2 />
                                    </SocialLink>

                                    <SocialLink onClick={() => window.open(collection.link_vk)}>
                                        <Vk2 />
                                    </SocialLink>

                                    <SocialLink
                                        onClick={() => window.open(collection.link_instagram)}
                                    >
                                        <Instagram2 />
                                    </SocialLink>

                                    <SocialLink>
                                        <Video2 />
                                    </SocialLink>
                                </SocialBlock>

                                <button className={styles.collectionOfferButton}>
                                    <span className={styles.collectionOfferButtonText}>
                                        Договор оферты
                                    </span>

                                    <span className={styles.collectionOfferButtonIcon}>
                                        <Download2 />
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className={styles.collectionMoreInfoMobile}>
                            <button
                                className={cn(styles.collectionMoreInfoOpen, {
                                    [styles.active]: moreInfoOpen,
                                })}
                                onClick={() => setMoreInfoOpen((prev) => !prev)}
                            >
                                Дополнительная информация
                                <span>{moreInfoOpen ? <Minus /> : <Plus2 />}</span>
                            </button>

                            {moreInfoOpen && (
                                <div className={styles.collectionMoreInfoContent}>
                                    <div className={styles.collectionMoreInfoContentWrap}>
                                        <SocialBlock className={styles.collectionInfoSocialContent}>
                                            <SocialLink>
                                                <Web2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Telegram2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Vk2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Instagram2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Video2 />
                                            </SocialLink>
                                        </SocialBlock>

                                        <div
                                            className={cn(
                                                styles.collectionInfoPoints,
                                                styles.mobile,
                                            )}
                                        >
                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    33,6 млн
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    объем продаж, руб
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    4,5 млн
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    мин. цена, руб
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    100
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    токенов
                                                </p>
                                            </div>

                                            <div className={styles.collectionInfoPoint}>
                                                <p className={styles.collectionInfoPointValue}>
                                                    95
                                                </p>
                                                <p className={styles.collectionInfoPointName}>
                                                    владельцев
                                                </p>
                                            </div>
                                        </div>

                                        <button className={styles.collectionOfferButton}>
                                            <span className={styles.collectionOfferButtonText}>
                                                Договор оферты
                                            </span>

                                            <span className={styles.collectionOfferButtonIcon}>
                                                <Download2 />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className={styles.collectionFilterInner}>
                            <button
                                className={styles.collectionFilterButton}
                                onClick={() => setFilterOpen((prev) => !prev)}
                            >
                                <Filter />

                                <span>Фильтр</span>
                            </button>

                            <button
                                className={styles.collectionFilterSearchButton}
                                onClick={() => setShowSearchFilter(true)}
                                ref={buttonRef}
                            >
                                <Search3 />
                                Поиск
                            </button>

                            <div
                                className={styles.collectionFilterInputWrapper}
                                ref={componentRef2}
                            >
                                <div
                                    className={cn(styles.collectionFilterInputInner, {
                                        [styles.searchFocus]: showSearchFilter,
                                    })}
                                >
                                    <input
                                        type="text"
                                        placeholder="Поиск по названию токена, характеристикам..."
                                        className={styles.collectionFilterInput}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        onFocus={() => setShowSearchFilter(true)}
                                    />

                                    <button
                                        className={styles.collectionFilterInputButton}
                                        onClick={() => setResetTokensList(true)}
                                    >
                                        Найти
                                    </button>
                                </div>
                            </div>

                            <div
                                className={cn(styles.collectionFilterInputInner, styles.mobile, {
                                    [styles.searchFilterMobile]: showSearchFilter,
                                })}
                                ref={componentRef}
                            >
                                <input
                                    type="text"
                                    placeholder="Поиск по названию токена, характеристикам..."
                                    className={styles.collectionFilterInput}
                                />

                                <button className={styles.collectionFilterInputButton}>
                                    Найти
                                </button>
                            </div>

                            <div className={styles.collectionFilterSort}>
                                <CustomSelect
                                    optionsList={SORT_BY_OPTIONS_ARR}
                                    value={sortBy}
                                    placeholder="Выбрать фильтр"
                                    onChange={handleSortList}
                                />
                            </div>

                            <Tabs className={styles.collectionFilterView}>
                                <Tab
                                    left
                                    active={view === 'small'}
                                    onClick={() => setView('small')}
                                >
                                    <ViewSmall />
                                </Tab>

                                <Tab active={view === 'normal'} onClick={() => setView('normal')}>
                                    <ViewMedium />
                                </Tab>

                                <Tab right active={view === 'big'} onClick={() => setView('big')}>
                                    <ViewBig />
                                </Tab>
                            </Tabs>
                        </div>

                        <div className={styles.collectionContent}>
                            <div
                                className={cn(styles.collectionFilter, {
                                    [styles.filterOpen]: filterOpen,
                                })}
                            >
                                <div
                                    className={styles.filterMobileElement}
                                    onClick={() => setFilterOpen((prev) => !prev)}
                                >
                                    <Filter />
                                    Фильтр
                                </div>

                                <FilterItemList title="Токены">
                                    <div className={styles.collectionFilterValueItems}>
                                        <FilterItem
                                            title="Проданы"
                                            onClick={() => handleSetPaidFilter('paid')}
                                            id={'paidTokens'}
                                        />
                                        <FilterItem
                                            title="В продаже"
                                            id={'paidTokens2'}
                                            onClick={() => handleSetPaidFilter('posted')}
                                        />
                                    </div>
                                </FilterItemList>

                                {/* <FilterItemList title="Цвет" titleValue="3">
                                    <div className={styles.collectionFilterSearchInner}>
                                        <input
                                            type="text"
                                            placeholder="Поиск..."
                                            className={styles.collectionFilterSearchInput}
                                        />

                                        <Search2 />
                                    </div>

                                    <FilterItem title="Белый" count="43" id="color1" />
                                    <FilterItem title="Черный" count="21" id="color2" />
                                    <FilterItem title="Красный" count="23" id="color3" />
                                </FilterItemList>

                                <FilterItemList title="Год выпуска" titleValue="10">
                                    <FilterItem title="2020" count="43" id="year1" />
                                    <FilterItem title="2022" count="21" id="year2" />
                                    <FilterItem title="2024" count="23" id="year3" />
                                </FilterItemList>

                                <FilterItemList title="Объем (л.)" titleValue="5">
                                    <FilterItem title="2.0" count="43" id="volume1" />
                                    <FilterItem title="2.5" count="21" id="volume2" />
                                    <FilterItem title="3.5" count="23" id="volume3" />
                                </FilterItemList> */}
                            </div>

                            <div className={styles.collectionContentInner}>
                                <div className={styles.collectionContentBar}>
                                    <div className={styles.collectionContentUpdateBar}>
                                        <button
                                            className={styles.collectionContentUpdateButton}
                                            onClick={() => setResetTokensList(true)}
                                        >
                                            <Reload />
                                        </button>

                                        <p>Обновлено 2 мин назад</p>
                                    </div>

                                    <p className={styles.collectionContentCount}>
                                        {items.length} шт.
                                    </p>
                                </div>

                                {/* <div className={styles.collectionFilterItems}>
                                    <button className={styles.collectionFilterItem}>
                                        Имя фильтра
                                        <Cross />
                                    </button>

                                    <button className={styles.collectionFilterItem}>
                                        Имя фильтра 2
                                        <Cross />
                                    </button>

                                    <button className={styles.collectionFilterClear}>
                                        Очистить всё
                                    </button>
                                </div> */}

                                <div
                                    className={cn(styles.collectionContentItems, {
                                        [styles.smallCards]: view === 'small',
                                        [styles.normalCards]: view === 'normal',
                                    })}
                                >
                                    {items.map((token) => {
                                        return (
                                            <CertificatesItem
                                                key={token.id}
                                                certificate={{
                                                    id: token.id,
                                                    name: token.name,
                                                    price: token.price,
                                                    file_1: `${process.env.REACT_APP_BACKET_BASE_URL}/${process.env.REACT_APP_BACKET_NAME}/${token.file_1}`,
                                                    investor_royalty: token.investor_royalty,
                                                    purchased: token.paid,
                                                }}
                                                view={view}
                                                filterOpen={filterOpen}
                                            />
                                        );
                                    })}
                                    {/* <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                            purchased: true,
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #002',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        invited={true}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    />

                                    <CertificatesItem
                                        certificate={{
                                            id: '1',
                                            name: 'Anastasiya #001',
                                            price: 1313200,
                                            file_1: '/assets/img/brand1.jpg',
                                            investor_royalty: '0.1',
                                        }}
                                        view={view}
                                        filterOpen={filterOpen}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    ) : (
        <div className={base.marginWrapper}>
            <CollectionNewSkeletonPage />
        </div>
    );
};

export default CollectionNew;
