import React from 'react';

const Share2 = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.64284 13.3627C9.09568 14.1918 8.14709 14.7406 7.06807 14.7406C5.37461 14.7406 4 13.3871 4 11.7198C4 10.0526 5.37472 8.69912 7.06807 8.69912C8.14705 8.69912 9.09564 9.2479 9.64284 10.077L15.7118 7.08975C15.6334 6.85732 15.5908 6.60809 15.5908 6.34964C15.5908 5.05236 16.6595 4 17.9772 4C19.295 4 20.3637 5.05225 20.3637 6.34964C20.3637 7.64704 19.295 8.69929 17.9772 8.69929C17.1659 8.69929 16.4483 8.29986 16.017 7.68983L9.94805 10.6779C10.0699 11.0026 10.1364 11.3534 10.1364 11.7201C10.1364 12.0868 10.0699 12.4375 9.94805 12.7623L16.017 15.7504C16.4483 15.1403 17.1659 14.7409 17.9772 14.7409C19.2949 14.7409 20.3637 15.7932 20.3637 17.0906C20.3637 18.388 19.295 19.4402 17.9772 19.4402C16.6595 19.4402 15.5908 18.388 15.5908 17.0906C15.5908 16.8321 15.6334 16.5829 15.7118 16.3505L9.64284 13.3627Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Share2;
