import React, { useContext, useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './index.module.css';
import base from '../../styles/base.module.css';

import { Tooltip } from '../../components/Tooltip';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import Telegram2 from '../../icons/Telegram2';
import WhatsApp2 from '../../icons/WhatsApp2';
import Instagram2 from '../../icons/Instagram2';
import Share2 from '../../icons/Share2';
// import ModalNew from '../../common/ModalNew';
// import { InputNew } from '../../common/InputNew';
// import { DescriptionBlock } from '../../components/DescriptionBlock';
// import { ModalNew2 } from '../../components/ModalNew2';
import { SocialBlock, SocialLink } from '../../components/SocialBlock';
import NOTIFICATION_TYPES from '../../const/notifications/NOTIFICATION_TYPES';
import { copyText } from '../../functions/utils';
import { NotificationContext } from '../../context/NotificationContext';
// import ProfileSkeletonPage from './ProfileSkeletonPage';
import { useGetMyTokensQuery } from '../../redux/api/dataService';
import { ProfileSertificates } from './components/ProfileSertificates/ProfileSerifiactes';
import { EditInfoModal } from './components/EditInfoModal/EditInfoModal';
import { VerifyContacts } from './components/VerifyContacts/VerifyContacts';
import { fetchUserByToken } from './utils/utils';
import { useDynamicPagination } from '../useDynamicPagination';
import { getShortWalletAddress } from '../../utils/getShortWalletAddress';
import ProfileSkeletonPage from './ProfileSkeletonPage';
import { useSelector } from 'react-redux';

const URL = `${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token/user/me/`;

const ProfileNew = () => {
    const accessToken = localStorage.getItem('access_token');
    const [contacts, setContacts] = useState({ email: '', phone: '' });
    const [userProfile, setUserProfile] = useState(null);
    const [resetList, setIsResetList] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [verifyProfileModal, setVerifyProfileModal] = useState(false);
    const [editProfileModal, setEditProfileModal] = React.useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [verifyData,setVerifyData] = useState({})
    const {
        actions: { addNotification },
    } = useContext(NotificationContext);

    useEffect(() => {
        if (!userProfile) {
            fetchUserByToken(setContacts, setUserProfile);
        }
    }, [userProfile]);

    const copyAddress = () => {
        copyText('0xeA09...daAF');
        addNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            text: 'Адрес скопирован!',
        });
    };
    return userProfile ? (
        <div className={base.marginWrapper}>
            <div className={styles.profileCircle}></div>

            <section className={styles.profile}>
                <div className={base.container}>
                    <div className={styles.profileInner}>
                        <div className={styles.profileContent}>
                            <div className={styles.profileInfo}>
                                {userProfile.image_url ? (
                                    <UserAvatar
                                        avatar={`${userProfile.image_url}`}
                                        style="bronze"
                                        size="big"
                                        className={styles.profileInfoAvatar}
                                    />
                                ) : (
                                    <UserAvatar
                                        avatar="/assets/img/avatar2.png"
                                        size="big"
                                        className={styles.profileInfoAvatar}
                                    />
                                )}

                                <div className={styles.profileInfoWrapper}>
                                    <div className={styles.profileNameInner}>
                                        <p className={styles.profileInfoId}>
                                            {userProfile && userProfile.username}
                                        </p>
                                        <p className={styles.profileInfoName}>
                                            {userProfile && userProfile.first_name
                                                ? userProfile.first_name
                                                : '-'}
                                        </p>
                                        <p
                                            className={styles.profileInfoWallet}
                                            onClick={copyAddress}
                                        >
                                            <svg
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.98792 0.398577C5.92461 0.400369 5.86262 0.417168 5.80707 0.447592C5.75152 0.478016 5.70399 0.521195 5.66839 0.573577L2.46839 5.26576C2.43616 5.31293 2.4144 5.36645 2.40459 5.42273C2.39477 5.479 2.39712 5.53673 2.41148 5.59202C2.42584 5.64731 2.45187 5.69889 2.48783 5.74328C2.52379 5.78766 2.56884 5.82384 2.61995 5.84936L5.81995 7.44701C5.8755 7.47479 5.93675 7.48925 5.99885 7.48925C6.06096 7.48925 6.12221 7.47479 6.17776 7.44701L9.37776 5.84936C9.42887 5.82384 9.47392 5.78766 9.50988 5.74328C9.54583 5.69889 9.57187 5.64731 9.58623 5.59202C9.60059 5.53673 9.60294 5.479 9.59312 5.42273C9.58331 5.36645 9.56155 5.31293 9.52932 5.26576L6.32932 0.573577C6.29164 0.518139 6.24062 0.473059 6.18097 0.442481C6.12131 0.411903 6.05493 0.396806 5.98792 0.398577ZM5.99885 1.50795V4.39858L8.60667 5.34076L5.99885 6.64233V4.39858L3.42932 5.27592L5.99885 1.50795ZM2.78401 6.79858C2.7099 6.80126 2.63799 6.82449 2.57632 6.86567C2.51465 6.90686 2.46564 6.96437 2.43476 7.0318C2.40388 7.09922 2.39236 7.1739 2.40147 7.2475C2.41058 7.3211 2.43997 7.39072 2.48635 7.44858L5.68635 11.4486C5.72383 11.4955 5.77138 11.5333 5.82547 11.5593C5.87957 11.5854 5.93883 11.5989 5.99885 11.5989C6.05888 11.5989 6.11814 11.5854 6.17223 11.5593C6.22633 11.5333 6.27388 11.4955 6.31135 11.4486L9.51135 7.44858C9.56842 7.37737 9.59943 7.2888 9.59924 7.19754C9.59904 7.10629 9.56765 7.01785 9.51028 6.94689C9.45291 6.87593 9.37301 6.82671 9.28382 6.80741C9.19463 6.78811 9.10153 6.79988 9.01995 6.84076L5.99885 8.3517L2.97776 6.84076C2.91774 6.81066 2.85111 6.79616 2.78401 6.79858ZM4.24964 8.37123L5.81995 9.15639C5.8755 9.18415 5.93675 9.19859 5.99885 9.19858V10.558L4.24964 8.37123Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            {userProfile &&
                                                getShortWalletAddress(userProfile.public_address)}
                                        </p>

                                        {/* <button
                                            className={cn(styles.profileButton, styles.mobile)}
                                            onClick={() => setVerifyProfileModal(true)}
                                        >
                                            Пройдите верификацию
                                        </button> */}
                                    </div>

                                    <div className={styles.profileSocialInner}>
                                        <div className={styles.profileInfoStats}>
                                            <p className={styles.profileInfoStat}>5 токенов</p>
                                            <p className={styles.profileInfoStat}>
                                                240,5 млн стоимость
                                            </p>
                                        </div>

                                        <SocialBlock>
                                            <SocialLink>
                                                <Telegram2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <WhatsApp2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Instagram2 />
                                            </SocialLink>

                                            <SocialLink>
                                                <Share2 />
                                            </SocialLink>
                                        </SocialBlock>
                                    </div>
                                </div>
                            </div>

                            <div className={cn(styles.profileSocialInner, styles.mobile)}>
                                <div className={styles.profileInfoStats}>
                                    <p className={styles.profileInfoStat}>5 токенов</p>
                                    <p className={styles.profileInfoStat}>240,5 млн стоимость</p>
                                </div>

                                <SocialBlock>
                                    <SocialLink>
                                        <Telegram2 />
                                    </SocialLink>

                                    <SocialLink>
                                        <WhatsApp2 />
                                    </SocialLink>

                                    <SocialLink>
                                        <Instagram2 />
                                    </SocialLink>

                                    <SocialLink>
                                        <Share2 />
                                    </SocialLink>
                                </SocialBlock>
                            </div>
                            {/* <div style={{display:'flex',flexDirection:'column',gap:'10px'}}> */}
                            {/* {!userProfile.phone_verified || !userProfile.email_verified ? (
                                <Tooltip text="Для приобретения токенов верифицируйте телефон или почту">
                                    <button
                                        className={styles.profileButton}
                                        onClick={() => setVerifyProfileModal((prev) => !prev)}
                                    >
                                        Пройдите верификацию
                                    </button>
                                </Tooltip>
                            ) : (
                                <></>
                            )} */}

                            <button
                                className={styles.profileButton}
                                onClick={() => setEditProfileModal((prev) => !prev)}
                            >
                                Редактировать профиль
                            </button>
                            {/* </div> */}
                        </div>

                        <ProfileSertificates />
                    </div>
                </div>
            </section>
            {editProfileModal && (
                <EditInfoModal
                    setEditProfileModal={setEditProfileModal}
                    editProfileModal={editProfileModal}
                    userProfile={userProfile}
                    setIsVerify={setIsVerify}
                    setVerifyData={setVerifyData}
                />
            )}
            {isVerify && (
                <VerifyContacts
                    verifyProfileModal={isVerify}
                    setVerifyProfileModal={setIsVerify}
                    // userProfile={userProfile}
                    verifyData={verifyData}
                />
            )}
        </div>
    ) : (
        <ProfileSkeletonPage />
    );
};

export default ProfileNew;
