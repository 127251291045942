exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FadeDescription_fadeDesc__3bZDy{\n    width: 100%;\n    max-width: 570px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    grid-gap: 4px;\n    gap: 4px;\n}\n\n.FadeDescription_fadeDesc__3bZDy p{\n    color: rgba(229, 232, 235, .76);\n    font-weight: 300;\n    font-size: 1.5rem;\n}\n\n.FadeDescription_fadeDesc__3bZDy.FadeDescription_white__2xBdv p{\n    color: #fff;\n}\n\n.FadeDescription_fadeDescTextShow__CvvzA{\n    padding-bottom: 0.4rem;\n    border-bottom: 1px dashed #207AE1;\n    color: #207AE1;\n    font-size: 1.4rem;\n}", ""]);

// exports
exports.locals = {
	"fadeDesc": "FadeDescription_fadeDesc__3bZDy",
	"white": "FadeDescription_white__2xBdv",
	"fadeDescTextShow": "FadeDescription_fadeDescTextShow__CvvzA"
};