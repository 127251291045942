import React, { memo, useEffect, useState } from 'react';
import styles from './index.module.css';
import cn from 'classnames';
import { PAGE_SIZE } from '.';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';

const COUNT_SIZE_BUTTON_PAGINATION = 3;

const PAGINATION_LINK = {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#181E30',
    fontSize: '1.5rem',
    fontWeight: '300',
    color: '#fff',
};
export const OwnersPagination = memo(({ currentPage, handleSetPage, totalPageCount }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Pagination
                page={currentPage}
                onChange={(_, number) => handleSetPage(number)}
                color="primary"
                renderItem={(item) => <PaginationItem sx={PAGINATION_LINK} {...item} />}
                count={totalPageCount}
                variant="outlined"
                shape="rounded"
            />
        </div>
    );
});
