import React from 'react';

export const Pen2 = ({ ...props }) => {
    return (
        <svg
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8272 2.54753L12.7134 3.66134L10.3387 1.28657L11.4525 0.172757C11.6831 -0.0576673 12.058 -0.0575041 12.2883 0.172757L13.8272 1.71166C14.0576 1.94209 14.0576 2.31714 13.8272 2.54753ZM1.45333 10.3293L0 14L3.67066 12.5467C3.45498 12.0578 3.1522 11.6168 2.76769 11.2324C2.38314 10.8479 1.94222 10.545 1.45333 10.3293ZM1.93726 9.68804C2.44887 9.93614 2.91358 10.2667 3.32345 10.6766C3.73332 11.0864 4.06393 11.5512 4.31197 12.0628L12.1577 4.21705L9.78299 1.84229L1.93726 9.68804Z"
                fill="currentColor"
            />
        </svg>
    );
};
