import React, { useRef } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Star2 from '../../../../icons/Star2';
import Star from '../../../../icons/Star';
import ReviewItem from '../../components/ReviewItem';
import styles from '../../index.module.css';
import base from '../../../../styles/base.module.css';

import ArrowLeftShort from '../../../../icons/ArrowLeftShort';
import ArrowRightShort from '../../../../icons/ArrowRightShort';

import { getReviews, calculatePercentages, calculatePercentagesStars } from '../../lib/reviews';
import { ReviewModal } from './ReviewModal';
import { ReviewModalThx } from './reviewModalThx';
import { AllInfoReview } from './allInfoReview/AllInfoReview';

const settings2 = {
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 998,
            settings: {
                slidesToShow: 2.05,
            },
        },
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 1.05,
            },
        },
    ],
};

const REVIEWS_PAGE_SIZE = 10;

export const Review = () => {
    const [reviews, setReviews] = React.useState(null);
    const [reviewsPrecent, setReviewsPrecent] = React.useState({
        percentOfFive: 0,
        percentToFive: 100,
    });
    const [isNext, setIsNext] = React.useState(null);
    const [starsPrecent, setStarsPrecent] = React.useState({
        1: '0%',
        2: '0%',
        3: '0%',
        4: '0%',
        5: '0%',
    });
    const [currentPage, setCurrentPage] = React.useState(1);
    const nextReview = () => {
        sliderRef2.slickNext();
    };

    const previousReview = () => {
        sliderRef2.slickPrev();
    };
    let sliderRef2 = React.useRef(null);

    const [reviewModal, setReviewModal] = React.useState(false);
    const [reviewModalThx, setReviewModalThx] = React.useState(false);

    const fetchReviews = async () => {
        const reviewsData = await getReviews(currentPage, REVIEWS_PAGE_SIZE);
        setReviews(reviewsData);
        setReviewsPrecent(calculatePercentages(reviewsData.statistics.average_rating || 0));
        setStarsPrecent(calculatePercentagesStars(reviewsData.statistics.star_counts));
    };

    React.useEffect(() => {
        fetchReviews();
    }, []);

    React.useEffect(() => {
        const fetchReviewsMore = async () => {
            getReviews(currentPage, REVIEWS_PAGE_SIZE)
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return new Error('error');
                    }
                })
                .then((response) => {
                    if (response && response.results) {
                        setReviews((prevReviews) => ({
                            ...prevReviews,
                            results: [...prevReviews.results, ...response.results],
                        }));
                    }
                })
                .catch((err) => {});
        };
        currentPage > 1 && fetchReviewsMore();
    }, [currentPage]);

    return (
        <section className={styles.reviews} id="reviews">
            <div className={base.container}>
                <div className={styles.reviewsInner}>
                    <div className={styles.reviewsTitleInner}>
                        <div id="reviews" className={styles.reviewsTitleWrapper}>
                            <h2 className={cn(base.title2, styles.reviewsTitle)}>
                                Отзывы клиентов
                            </h2>

                            <p className={styles.reviewsText}>Примите решение на основе отзывов</p>
                        </div>

                        {reviews && reviews.results && reviews.results.length > 0 && (
                            <div className={styles.reviewsArrows}>
                                <button className={styles.teamArrow} onClick={previousReview}>
                                    <ArrowLeftShort />
                                </button>

                                <button className={styles.teamArrow} onClick={nextReview}>
                                    <ArrowRightShort />
                                </button>
                            </div>
                        )}
                    </div>

                    <div className={styles.reviewsContent}>
                        {reviews && reviews.results && reviews.results.length > 0 ? (
                            <Slider
                                ref={(slider) => {
                                    sliderRef2 = slider;
                                }}
                                {...settings2}
                            >
                                <AllInfoReview
                                    reviews={reviews}
                                    reviewsPrecent={reviewsPrecent}
                                    starsPrecent={starsPrecent}
                                    setReviewModal={setReviewModal}
                                />
                                {reviews &&
                                    reviews.results &&
                                    reviews.results.map((review) => (
                                        <ReviewItem
                                            key={review.id}
                                            id={review.id}
                                            name={review.name}
                                            role={
                                                review.type == 'user'
                                                    ? 'Пользователь'
                                                    : 'Представитель бренда'
                                            }
                                            text={review.review}
                                            logo={review.logo}
                                            stars={review.stars}
                                            link={review.link}
                                            date={review.created_at}
                                            refechReview={fetchReviews}
                                        />
                                    ))}
                            </Slider>
                        ) : (
                            <AllInfoReview
                                reviews={reviews}
                                reviewsPrecent={reviewsPrecent}
                                starsPrecent={starsPrecent}
                                setReviewModal={setReviewModal}
                            />
                        )}
                    </div>

                    {reviews && reviews.results && reviews.results.length > 0 && (
                        <div className={cn(styles.brandsAll, styles.reviewsAll)}>
                            <button
                                className={cn(base.orangeButton, styles.brandsAllButton)}
                                onClick={() => setCurrentPage(currentPage + 1)}
                            >
                                Смотреть все отзывы
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {reviewModal && (
                <ReviewModal
                    reviewModal={reviewModal}
                    setReviewModal={setReviewModal}
                    setReviewModalThx={setReviewModalThx}
                    refechReview={fetchReviews}
                />
            )}
            {/* {reviewModalThx && (
                <ReviewModalThx
                    reviewModalThx={reviewModalThx}
                    setReviewModalThx={setReviewModalThx}
                />
            )} */}
        </section>
    );
};
