export const generateQueryParams = (params) => {
    const filteredParams = params.filter(
        (param) => param.value !== null && String(param.value).length > 0,
    );

    const query = filteredParams
        .map((param) => `${param.title}=${encodeURIComponent(param.value)}`)
        .join('&');
    return query.length > 0 ? `?${query}` : '';
};

export const expandListOfParameters = (params, additional) => {
    return [...params, ...additional];
};
