import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import { BrandsContext } from './components/Brands/Brands';
import { CollectionsContext } from './components/Collections/Collections';
import { CertificatesContext } from './components/Certificate/Cetrificates';
import {
    useGetAccountsListFilteredQuery,
    useGetFilteredCollectionsQuery,
} from '../../redux/api/handleService';
import { useGetFilteredTokensQuery } from '../../redux/api/dataService';

const pathnames = ['/brandsnew/brands', '/brandsnew/collections', '/brandsnew/certificates'];

const BrandsNew = () => {
    const { pathname, search } = useLocation();
    const tabinitValue = window.location.pathname.split('/').at(-1);
    const [tab, setTab] = React.useState(tabinitValue);
    // const [isLoading, setIsLoading] = React.useState(true);

    const [countBrand, setCountBrand] = React.useState(null);
    const [countCollection, setCountCollection] = React.useState(null);
    const [countCertificate, setCountCertificate] = React.useState(null);

    const isFirstRender = useRef(true);
    const [count, setCount] = useState(null);
    useEffect(() => {
        if (isFirstRender.current === true) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/handler_service/api/v1/general/`)
                .then((res) => {
                    if (Number(res.status) === 200) {
                        return res.json();
                    }
                    if (Number(res.status) >= 400) {
                        return res.json();
                    }
                })
                .then((response) => {
                    setCount(response);
                })
                .catch((err) => {});
            isFirstRender.current = false;
        }
    }, [isFirstRender.current]);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (pathname === '/brandsnew' || !pathnames.includes(pathname)) {
            navigate(`/brandsnew/brands${search}`);
        } else {
            setTab(pathname.split('/')[2]);
        }
    }, [pathname]);

    // const { data: brandsTokens, isLoading: isBrandLoading } = useGetAccountsListFilteredQuery({
    //     page: 1,
    //     pageSize: 1,
    // });
    // const { data: collectionTokens, isLoading: isCollectionLoading } =
    //     useGetFilteredCollectionsQuery({ page: 1, pageSize: 1 });
    // const { data: certificateTokens, isLoading: isCertificateLoading } = useGetFilteredTokensQuery({
    //     page: 1,
    //     pageSize: 1,
    // });

    // React.useEffect(() => {
    //     if (!isBrandLoading && !isCollectionLoading && !isCertificateLoading) {
    //         setIsLoading(false);
    //     }
    // }, [isBrandLoading, isCollectionLoading, isCertificateLoading]);

    // React.useEffect(() => {
    //     brandsTokens && setCountBrand(brandsTokens.count);
    //     certificateTokens && setCountCertificate(certificateTokens.count);
    //     collectionTokens && setCountCollection(collectionTokens.count);
    // }, [brandsTokens, collectionTokens, certificateTokens]);

    return (
        <div className={styles.marginWrapper}>
            <div className={styles.brandTabs}>
                <img src="/assets/img/brand-bg.svg" alt="brand bg" className={styles.brandBg} />

                <div className={base.container}>
                    <div className={styles.brandTabsInner}>
                        {/* {isLoading ? (
                            <>
                                <div
                                    className={cn(styles.brandTab, styles.brandTabSkelet, {
                                        [styles.active]: pathname.split('/')[2] === 'brands',
                                    })}
                                >
                                    <Skeleton containerClassName={styles.brandTabTextSkeleton} />

                                    <Skeleton
                                        containerClassName={styles.brandTabCountSkeletonWrapper}
                                        className={styles.brandSubTabSkeleton}
                                    />
                                </div>

                                <div
                                    className={cn(styles.brandTab, styles.brandTabSkelet, {
                                        [styles.active]: pathname.split('/')[2] === 'collections',
                                    })}
                                >
                                    <Skeleton containerClassName={styles.brandTabTextSkeleton} />

                                    <Skeleton
                                        containerClassName={styles.brandTabCountSkeletonWrapper}
                                        className={styles.brandSubTabSkeleton}
                                    />
                                </div>

                                <div
                                    className={cn(styles.brandTab, styles.brandTabSkelet, {
                                        [styles.active]: pathname.split('/')[2] === 'certificates',
                                    })}
                                >
                                    <Skeleton containerClassName={styles.brandTabTextSkeleton} />

                                    <Skeleton
                                        containerClassName={styles.brandTabCountSkeletonWrapper}
                                        className={styles.brandSubTabSkeleton}
                                    />
                                </div>
                            </>
                        ) : (
                           
                        )} */}

                        <>
                            <Link
                                to="brands"
                                className={cn(styles.brandTab, {
                                    [styles.active]: pathname.split('/')[2] === 'brands',
                                })}
                            >
                                Бренды
                                <span className={styles.brandTabCount}>
                                    {' '}
                                    {count && count.total_number_accounts}{' '}
                                </span>
                            </Link>

                            <Link
                                to="collections"
                                className={cn(styles.brandTab, styles.brandTabCollection, {
                                    [styles.active]: pathname.split('/')[2] === 'collections',
                                })}
                            >
                                Коллекции
                                <span className={styles.brandTabCount}>
                                    {' '}
                                    {count && count.total_number_collections}
                                </span>
                            </Link>

                            <Link
                                to="certificates"
                                className={cn(styles.brandTab, styles.brandTabCertificate, {
                                    [styles.active]: pathname.split('/')[2] === 'certificates',
                                })}
                            >
                                Сертификаты
                                <span className={styles.brandTabCount}>
                                    {' '}
                                    {count && count.total_number_tokens}
                                </span>
                            </Link>
                        </>
                    </div>
                </div>
            </div>

            <section className={styles.brands}>
                <div className={base.container}>
                    <div className={styles.brandsInner}>
                        {tab === 'brands' && <BrandsContext setCount={setCountBrand} />}
                        {tab === 'collections' && (
                            <CollectionsContext setCount={setCountCollection} />
                        )}
                        {tab === 'certificates' && (
                            <CertificatesContext setCount={setCountCertificate} />
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BrandsNew;
