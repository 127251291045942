import React, { useState, useRef } from 'react';
import { ModalNew2 } from '../../components/ModalNew2';
import Document from '../../icons/Document';
import { InputNew } from '../../common/InputNew';
import { Pen2 } from '../../icons/Pen2';
import cn from 'classnames';
// import QRCode from 'react-qr-code';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
const translateValueByType = (selected) => {
    const variants = {
        email: 'email',
        phone: 'телефон',
        wallet: 'кошелек',
    };
    return variants[selected];
};

const handleTransferToken = (token_id, newOwnerContact, setTransferSuccess,setModalCertificateSend) => {
    if (!token_id || !newOwnerContact) return;
    const access_token = localStorage.getItem('access_token');
    console.log(token_id, newOwnerContact);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin_service/api/v1/token_transfer/`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            token_id: token_id,
            new_owner_wallet: newOwnerContact,
        }),
    }).then((res) => {
        if (Number(res.status) < 210) {
            setTransferSuccess(true);
            setModalCertificateSend(false)
        }
    });
};

export const TokenTransfer = ({
    modalCertificateSend,
    setModalCertificateSend,
    token_id,
    setTransferSuccess,
}) => {
    const [userContacts, setUserContacts] = useState({ email: '' });
    const [selectedContactType, setSelectedContactType] = useState('');
    const inputRef = useRef(null);
    const handleSetContactTypeByValue = (type) => {
        setSelectedContactType(type);
        setUserContacts((prev) => {
            return { [type]: '' };
        });
        const input = inputRef.current;
        if (input) {
            input.value = '';
        }
    };
    const handleSetUserContacts = (value) => {
        setUserContacts((prev) => ({ [selectedContactType]: value }));
    };

    return (
        <ModalNew2
            isSteps
            title="Передача сертификата"
            icon={<Document />}
            value={modalCertificateSend}
            setValue={setModalCertificateSend}
        >
            <div className={styles.tokenModalCert}>
                <p className={styles.tokenModalOrderAvtoTitle}>Цифровой сертификат</p>

                <div className={styles.tokenModalCard} style={{ margin: '-15px 0' }}>
                    <div className={styles.tokenModalCardImg}>
                        <img src="/assets/img/token-img2.png" alt="token" />
                    </div>

                    <div className={styles.tokenModalInfo}>
                        <div className={styles.tokenModalWrapperInfo}>
                            <p className={styles.tokenModalCollectionName}>Rolls-Royce</p>

                            <p className={styles.tokenModalTokenName}>Rolls-Royce #001</p>
                        </div>
                    </div>
                </div>

                <div className={styles.tokenModalInputsWrap}>
                    <InputNew title="Стоимость автомобиля" placeholder="22 450 000">
                        <button className={styles.inputBlockPenButton}>
                            <Pen2 />
                        </button>
                    </InputNew>

                    <div className={styles.inputBlockChoice}>
                        <p className={styles.inputBlockChoiceText}>
                            Заполните одно из полей для передачи токена пользователю:
                        </p>

                        <div className={styles.inputBlockChoiceContent}>
                            <div className={styles.inputBlockChoiceItem}>
                                <input
                                    type="radio"
                                    className={styles.radio}
                                    id="email"
                                    name="sendType"
                                    onChange={(e) => {
                                        handleSetContactTypeByValue('email');
                                    }}
                                />

                                <label htmlFor="email" className={styles.inputBlockChoiceLabel}>
                                    Адрес эл. почты
                                </label>
                            </div>

                            <div className={styles.inputBlockChoiceItem}>
                                <input
                                    type="radio"
                                    className={styles.radio}
                                    id="phone"
                                    name="sendType"
                                    ref={inputRef}
                                    value={userContacts[selectedContactType]}
                                    onChange={(e) => {
                                        handleSetContactTypeByValue('phone');
                                    }}
                                />

                                <label htmlFor="phone" className={styles.inputBlockChoiceLabel}>
                                    Телефон
                                </label>
                            </div>

                            <div className={styles.inputBlockChoiceItem}>
                                <input
                                    type="radio"
                                    className={styles.radio}
                                    id="wallet"
                                    name="sendType"
                                    onChange={(e) => {
                                        handleSetContactTypeByValue('wallet');
                                    }}
                                />

                                <label htmlFor="wallet" className={styles.inputBlockChoiceLabel}>
                                    Кошелек
                                </label>
                            </div>
                        </div>

                        {selectedContactType && (
                            <div className={styles.inputBlockChoiceInputInner}>
                                <input
                                    type="text"
                                    ref={inputRef}
                                    className={styles.inputBlockChoiceInput}
                                    onChange={(e) => {
                                        handleSetUserContacts(e.target.value);
                                    }}
                                    placeholder={`Введите ${translateValueByType(
                                        selectedContactType,
                                    )} получателя`}
                                />

                                <button className={styles.inputBlockChoiceButton}>Проверить</button>
                            </div>
                        )}
                        {/* 
                        <InputNew value="naul@mail.ru" successMessage="Найден" success />

                        <InputNew value="naul@mail.ru" errorMessage="Не найден" error /> */}
                    </div>
                </div>

                <button
                    className={cn(base.orangeButton, styles.tokenModalDescButton)}
                    onClick={() => {
                        handleTransferToken(
                            token_id,
                            userContacts[selectedContactType],
                            setTransferSuccess,
                            setModalCertificateSend,
                        );
                    }}
                >
                    Передать
                </button>
            </div>
        </ModalNew2>
    );
};

{
    /* <ModalNew2
title="Отложенная отправка"
value={modalCertificateDelay}
setValue={setModalCertificateDelay}
>
<div className={styles.tokenModalCert}>
    <div className={styles.tokenModalTextTooltip}>
        <p className={styles.tokenModalCertTextAuto}>
            Токен переведен в статус «Передача»
        </p>

        <Tooltip
            placement="top"
            text='Сертификат будет передан пользователю только после завершения им процесса регистрации на платформе. До этого момента сертификат будет находиться в статусе "Передача". Вы также можете отменить процесс передачи в любое время до его завершения.'
            wrapperStyles={{
                width: 191,
            }}
            style={{
                position: 'relative',
                top: '-10px',
            }}
        >
            <Info2 className={styles.tokenMainBlockPriceInfo} />
        </Tooltip>
    </div>

    <button
        className={cn(base.orangeButton, styles.modalTokenButton)}
        onClick={() => setModalCertificateDelay(false)}
    >
        Хорошо
    </button>
</div>
</ModalNew2>

<ModalNew2
title="Отмена передачи токена"
value={modalCertificateCancel}
setValue={setModalCertificateCancel}
>
<div className={styles.tokenModalCert}>
    <p className={styles.tokenModalCertText}>
        Вы действительно отменяете передачу токена?
    </p>

    <div className={styles.tokenModalCertButtons}>
        <button className={cn(base.orangeButton, styles.modalTokenButton)}>
            Подтверждаю отмену
        </button>

        <button
            className={cn(
                base.orangeButton,
                styles.modalTokenButton,
                styles.disabled,
            )}
        >
            Не подтверждаю
        </button>
    </div>
</div>
</ModalNew2> */
}
