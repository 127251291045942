import React from 'react';

const Reload = ({ ...props }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.40678 6C8.34953 6 8.29146 6.01408 8.23763 6.04374C8.17069 6.08055 8.12002 6.13572 8.08894 6.20021L6.0617 9.5476L6.06057 9.54789C6.02398 9.60258 6.00191 9.66814 6.00012 9.73909C5.99602 9.90159 6.09971 10.0416 6.24572 10.0896L6.24483 10.0898C6.28166 10.1019 6.31962 10.1077 6.35743 10.1077C6.36448 10.1077 6.3715 10.1075 6.37854 10.1071L6.37956 10.1074L10.2637 10.2078C10.2723 10.2085 10.2809 10.2088 10.2895 10.2088C10.3461 10.2088 10.4019 10.1947 10.4558 10.1652C10.6278 10.0707 10.6912 9.85292 10.5977 9.67914L10.1888 8.92163C10.1978 8.91659 10.2068 8.91156 10.2159 8.90658C10.8065 8.58199 11.4435 8.42798 12.0716 8.42798C13.4513 8.42798 14.7879 9.17125 15.4935 10.4822C16.5203 12.39 15.8221 14.7776 13.9342 15.8152C13.3436 16.1398 12.7065 16.2938 12.0784 16.2938C11.081 16.2938 10.1062 15.9055 9.37616 15.1952C9.15586 14.9831 8.86281 14.8671 8.56316 14.8671C8.37327 14.8671 8.18068 14.9136 8.00218 15.0117C7.43142 15.3254 7.22041 16.0472 7.53082 16.624C7.59027 16.7344 7.66573 16.8289 7.75105 16.9118L7.75176 16.9124C8.9224 18.0485 10.4835 18.6694 12.0807 18.6694C13.0881 18.6694 14.1099 18.4224 15.0572 17.9018C18.0854 16.2374 19.2052 12.4076 17.5582 9.34739C16.4264 7.24457 14.2826 6.05239 12.0695 6.05239C11.062 6.05239 10.0402 6.29943 9.09294 6.82007C9.08419 6.82486 9.07546 6.82971 9.06688 6.8348L8.71826 6.18708C8.65401 6.06771 8.53236 6 8.40678 6Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Reload;
