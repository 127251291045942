import React from 'react';

const Picture = ({ ...props }) => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.7969 11.4953C17.211 11.4953 18.3566 12.5928 18.3566 13.9477C18.3566 15.3026 17.211 16.4001 15.7969 16.4001C14.3828 16.4001 13.2373 15.3026 13.2373 13.9477C13.2373 12.5928 14.3828 11.4953 15.7959 11.4953H15.7969ZM22.3374 16.135V16.136H22.3384C22.4717 16.136 22.604 16.1848 22.7057 16.2813L25.7701 19.2172V23.5206C25.7701 23.7965 25.5371 24.0197 25.2502 24.0206L10.7498 24.0216C10.4629 24.0197 10.2299 23.7964 10.2299 23.5206V20.6091L12.3969 18.532C12.4986 18.4355 12.6319 18.3867 12.7652 18.3867C12.8984 18.3867 13.0317 18.4355 13.1335 18.5329L15.9403 21.2221C16.0522 21.3293 16.2058 21.3956 16.3767 21.3956C16.5425 21.3956 16.6941 21.3323 16.805 21.2299L21.9691 16.2812C22.0708 16.1838 22.2041 16.135 22.3374 16.135ZM25.2695 9H10.7305C9.77318 9.01072 9 9.75834 9 10.6775V23.5205C9 24.4407 9.7732 25.1883 10.7305 25.199L25.2695 25.2C26.2278 25.1883 27 24.4407 27 23.5205V10.6775C27 9.75835 26.2278 9.01073 25.2695 9Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Picture;
