import React, { memo } from 'react';

import styles from './index.module.css';

const TokenInfo = memo(({ children, token }) => {
    return (
        <div className={styles.brandItemInfoButtonWrapper}>
            {children}

            <div className={styles.brandItemInfoPopupWrapper}>
                <div className={styles.brandItemInfoPopup}>
                    <div className={styles.brandItemInfoPopupContent}>
                        <div className={styles.brandItemInfoWeight}>
                            <img
                                src={token.file_1}
                                alt="logo"
                                className={styles.brandItemInfoWeightImg}
                            />

                            <div className={styles.brandItemInfoWeightInner}>
                                <p className={styles.brandItemInfoWeightText}>Куплен за</p>

                                <p className={styles.brandItemInfoTag}>
                                    {Math.floor(token.price / 100)} RUB
                                </p>
                            </div>
                        </div>

                        <p className={styles.brandItemInfoPopupName}>{token.name}</p>

                        <div className={styles.brandItemInfoPopupPoints}>
                            {token.properties &&
                                token.properties.map((prop, index) => {
                                    return (
                                        <p className={styles.brandItemInfoPopupPoint} key={index}>
                                            {prop.name}
                                        </p>
                                    );
                                })}
                        </div>

                        <p className={styles.brandItemInfoPopupText}>{token.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default TokenInfo;

{
    /* <p className={styles.brandItemInfoPopupPoint}>Lexus RX 300</p>
                            <p className={styles.brandItemInfoPopupPoint}>Белый</p>
                            <p className={styles.brandItemInfoPopupPoint}>А777АА777</p>
                            <p className={styles.brandItemInfoPopupPoint}>Premium</p>
                            <p className={styles.brandItemInfoPopupPoint}>2024</p>
                            
                            <p className={styles.brandItemInfoPopupPoint}>4,5</p> */
}
