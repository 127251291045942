import React from 'react';

const Telegram2 = ({ ...props }) => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            { ...props }
        >
            <path
                d="M4.16866 11.027C8.73205 9.05126 11.775 7.74875 13.2976 7.11944C17.6448 5.32264 18.5481 5.01052 19.1369 5.0001C19.2664 4.99794 19.5559 5.02983 19.7435 5.18106C19.9018 5.30876 19.9454 5.48125 19.9663 5.60232C19.9871 5.72339 20.0131 5.99919 19.9924 6.21469C19.7569 8.67437 18.7375 14.6434 18.2189 17.3983C17.9995 18.564 17.5674 18.9548 17.1492 18.9931C16.2401 19.0762 15.5498 18.3961 14.6694 17.8226C13.2916 16.9251 12.5133 16.3664 11.176 15.4907C9.63045 14.4786 10.6323 13.9224 11.5131 13.0133C11.7436 12.7754 15.7489 9.15516 15.8264 8.82675C15.8361 8.78568 15.8451 8.63258 15.7536 8.55173C15.6621 8.47089 15.527 8.49853 15.4295 8.52052C15.2913 8.55169 13.0904 9.9973 8.82663 12.8573C8.2019 13.2836 7.63604 13.4913 7.12904 13.4805C6.57012 13.4685 5.49498 13.1664 4.69573 12.9083C3.71541 12.5916 2.93627 12.4242 3.00412 11.8864C3.03946 11.6063 3.42764 11.3198 4.16866 11.027Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Telegram2;
