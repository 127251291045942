import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import styles from './index.module.css';
import base from '../../styles/base.module.css';
import CertificatesItemSkeleton from '../BrandsNew/components/CertificatesItemSkeleton';

const ProfileSkeletonPage = () => {
    return (
        <section className={styles.profile}>
            <div className={base.container}>
                <div className={styles.profileInner}>
                    <div className={styles.profileContent}>
                        <div className={styles.profileInfo}>
                            <Skeleton
                                containerClassName={styles.profileAvatarMobileSkeletonWrapper}
                                className={styles.skeletonBrs}
                            />

                            <div className={styles.profileInfoWrapper}>
                                <div className={styles.profileNameInner}>
                                    <Skeleton
                                        containerClassName={styles.profileRoleMobileSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.profileNameMobileSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.profileAddressSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />

                                    <Skeleton
                                        containerClassName={styles.profileButtonMobileSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>

                                <div className={styles.profileSocialInner}>
                                    <div className={styles.profileInfoStats}>
                                        <Skeleton
                                            containerClassName={styles.profilePointSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />

                                        <Skeleton
                                            containerClassName={styles.profilePointSkeletonWrapper}
                                            className={styles.skeletonBrs15}
                                        />
                                    </div>

                                    <Skeleton
                                        containerClassName={styles.profileSocialSkeletonWrapper}
                                        className={styles.skeletonBrs15}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={cn(styles.profileSocialInner, styles.mobile)}>
                            <div className={styles.profileInfoStats}>
                                <Skeleton
                                    containerClassName={styles.profilePointSkeletonWrapper}
                                    className={styles.skeletonBrs15}
                                />

                                <Skeleton
                                    containerClassName={styles.profilePointSkeletonWrapper}
                                    className={styles.skeletonBrs15}
                                />
                            </div>

                            <Skeleton
                                containerClassName={styles.profileSocialSkeletonWrapper}
                                className={styles.skeletonBrs15}
                            />
                        </div>

                        <Skeleton
                            containerClassName={styles.profileButtonSkeletonWrapper}
                            className={styles.skeletonBrs15}
                        />
                    </div>

                    <div className={styles.profileTokensContent}>
                        <Skeleton
                            containerClassName={styles.profileTabsSkeletonWrapper}
                            className={styles.skeletonBrs15}
                        />

                        <div className={styles.profileTokensItems}>
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                            <CertificatesItemSkeleton />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileSkeletonPage;
