import React, { memo, useState } from 'react';
import { ModalNew2 } from '../../components/ModalNew2';
import cn from 'classnames';
import styles from './index.module.css';
import base from '../../styles/base.module.css';
import AddFile from '../../icons/AddFile';
import Pen from '../../icons/Pen';
import { InputNew } from '../../common/InputNew';

export const TokenEdit = memo(({ tokenId }) => {
    const [urlModal, setUrlModal] = useState(false);
    const [descModal, setDescModal] = useState(false);
    const [toneInfoPayload, setTokenInfoPayload] = useState({})

    const handleSetInfoByType = (type,value) => {
        setTokenInfoPayload((prev) => ({...prev,[type]:value}))
    }

    

    
    return (
        <>
            <button className={styles.tokenInfoDescButton} onClick={() => setUrlModal(true)}>
                + ссылку
            </button>

            <button className={styles.tokenInfoDescButton}>
                <AddFile />
                фото
            </button>

            <button className={styles.tokenInfoDescButton} onClick={() => setDescModal(true)}>
                <Pen />
                описание
            </button>

            <ModalNew2
                value={descModal}
                setValue={setDescModal}
                title="Редактор описания"
                size="small"
                contentClassName={styles.descModal}
            >
                <div className={styles.tokenModalDesc}>
                    <div className={styles.tokenModalDescTextareaInner}>
                        <textarea
                            className={styles.tokenModalDescTextarea}
                            value=""
                            onChange={(e) => {
                                handleSetInfoByType('description', e.target.value);
                            }}
                        />

                        <p className={styles.textareaLength}>0/500</p>
                    </div>

                    <button className={cn(base.orangeButton, styles.tokenModalDescButtonSmall)}>
                        Сохранить
                    </button>
                </div>
            </ModalNew2>

            <ModalNew2
                value={urlModal}
                setValue={setUrlModal}
                size="small"
                contentClassName={styles.urlModal}
                title="URL ссылки"
            >
                <div className={styles.tokenModalUrl}>
                    <p className={styles.tokenModalUrlAdd}>Добавьте URL при необходимости</p>

                    <div className={styles.tokenModalUrlItems}>
                        <InputNew
                            title="URL 1"
                            placeholder="Введите https://"
                            setValue={(value) => {
                                handleSetInfoByType('link', value);
                            }}
                        />

                        {/* <InputNew title="URL 2" placeholder="Введите https://" /> */}
                    </div>

                    <button className={cn(base.orangeButton, styles.tokenModalDescButtonSmall)}>
                        Сохранить
                    </button>
                </div>
            </ModalNew2>
        </>
    );
});
